import moment from 'moment';
import axios from 'axios';
import { config } from './Configurator'

import { executeUpdateCase } from './api/UpdateCase';
import { executeMasterUpdateCase } from './api/MasterUpdateCase';
import { executeUpdateCaseStripped } from './api/UpdateCaseStripped';
import { executeUpdateCaseReducedSec } from './api/UpdateCaseReducedSec';
import { executeUpdateCaseLend } from './api/UpdateCaseLend';
import { executeUpdateCustomer } from './api/UpdateCustomer'
import { executeUpdateCustomerUpdateCase } from './api/UpdateCustomerUpdateCase'
import { executeUpdateCustomerUpdateCaseReduced } from './api/UpdateCustomerUpdateCaseReduced'
import { executeCreateNewProspectV2 } from './api/CreateNewProspectV2';
import { executeCreateNewProspectV2Peregrine } from './api/CreateNewProspectV2Peregrine';
import { executeCreateNewProspectV2Lend } from './api/CreateNewProspectV2Lend';
import { executeCreateCaseJSON } from './api/CreateCaseJSON';
import { executeMasterCreateCaseJSON } from './api/MasterCreateCaseJSON';
import { executeCreateCaseJSONPeregrine } from './api/CreateCaseJSONPeregrine';
import { executeCreateCaseJSONLend } from './api/CreateCaseJSONLend';

//export const currency = (m) => {
//    if(isNaN(m) || !m) {
//        return m;
//    }
//    return '£' + m.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//}

export const twoDecimals = (v) => 
{
    v = convertToDouble(v);
    return v.toFixed(2);
}

export const currency = (amount, decimalCount = 2, decimal = ".", thousands = ",") => 
{

    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    
        const negativeSign = amount < 0 ? "-" : "";
    
        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
    
        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
      } catch (e) {
        console.log(e)
      }
    
}

export const pmt_calc = (amount, terms_total, att) => {

    // 01 INPUTS
    // Calculate freq. to years
    let loan_in_years = 0;		// TERM OF LOAN IN YEARS
    let periods_per_year = 0;
    // let terms_total = parseInt(convertToDouble(att.payment_profile1) + convertToDouble(att.payment_profile2));
    let balloon = convertToDouble(att.balloon);

    if(att.frequency=='Monthly') {loan_in_years = terms_total / 12; periods_per_year = 12; }
    if(att.frequency=='Quarterly') {loan_in_years = terms_total / 4; periods_per_year = 4; }  
    if(att.frequency=='Half Year') {loan_in_years = terms_total / 2; periods_per_year = 2; }
    if(att.frequency=='Yearly') {loan_in_years = terms_total / 1; periods_per_year = 1; }  

    let _d7 = att.yield / 100;		// ANUAL INTEREST RATE (6%=0.06)
    let _d14 = periods_per_year; 	// periods per year
    let _d15 = 12;  	            // <== hard-coded to daily (was 12 monthly) 365.555

    // 02 MATH
    let rate = Math.pow((1+_d7/_d15), (_d15/_d14))-1;    // OK
    let nper = loan_in_years * _d14 ;                   // OK
    //let amount = convertToDouble(att.amount_financed);  // OK
    let pmtType = (att.payment_profile1 && att.payment_profile1>0) ? 1 : 0; 
    //console.log('pmt_calc original amount=' + amount);

    //if(balloon) {
    //    amount = amount - balloon;
    //}

    // 03 POR-97 transformation:
    // Interest Calculation is based on ‘amount_financed’ - ‘ballon_amount’ - 'subsidy_amount' - (totalAmountFinance/TotalNumberOfPayments)*numberOfUpfrontPayments)
    // let total_amoun_finance = convertToDouble(att.amount_financed);
    // let total_number_of_payments = terms_total;
    // let number_of_upfront_payments = parseInt(att.payment_profile1);
    // amount = amount - convertToDouble(att.balloon) - convertToDouble(att.subsidy) - ((total_amoun_finance/total_number_of_payments) * number_of_upfront_payments);
    // console.log('pmt_calc amount after transformation=' + amount);

    let ret = -pmt(rate, nper, amount, null, pmtType);
    console.log('pmt_calc=' + ret)
    return ret; 
}

export const pmt = (ir, np, pv, fv, type) => {
    /*
    * ir   - interest rate per month
    * np   - number of periods (months)
    * pv   - present value
    * fv   - future value
    * type - when the payments are due:
    *        0: end of the period, e.g. end of month (default)
    *        1: beginning of period
    */
    var pmt, pvif;

    fv || (fv = 0);
    type || (type = 0);

    if (ir === 0)
    return -(pv + fv)/np;

    pvif = Math.pow(1 + ir, np);
    pmt = - ir * (pv * pvif + fv) / (pvif - 1);

    if (type === 1)
    pmt /= (1 + ir);

    return pmt;
}


export const convertDate = (d) => {
    if(!d) {
        return '';
    }
    // input: /Date(1595890800000)/	
    let unix_timestamp = d.replace('/Date(', '').replace(')/', '');
    let m = moment.unix(unix_timestamp/1000);
    return m.format("DD-MM-YYYY");
}

export const convertDateForApi = (d) => {
    if(!d) return '';
    let r = moment(d, 'D-M-YYYY', true);
    return r.isValid() ? r.format("YYYY-MM-DD") : d;
}

export const convertToDouble = (d) => {

    if(!d || d==null) return 0;
    d = d + ''; // cast
    d = d.split(',').join('');
    let r = parseFloat(d.toString());
    // console.log('convertToDouble out=' + r);
    return r;
}

export const dropLocalStorageData = () => {
    localStorage.removeItem('bo_form_data2');
}

export const boApiSendProposal = (endpoint, data, formik, applicant_id=null, customer_reference=null) => {


    formik.setSubmitting(false);

    let att = data.attributes; // shorthand
    let r = false;

    if(endpoint === 'UpdateCase') {
        r = executeUpdateCase(att, applicant_id, customer_reference);
    }

    if(endpoint === 'MasterUpdateCase') {
        r = executeMasterUpdateCase(att, applicant_id, customer_reference);
    }

    if(endpoint === 'UpdateCaseStripped') {
        r = executeUpdateCaseStripped(att, applicant_id, customer_reference);
    }

    if(endpoint === 'UpdateCaseReducedSec') {
        r = executeUpdateCaseReducedSec(att, applicant_id, customer_reference);
    }

    if(endpoint === 'UpdateCaseLend') {
        r = executeUpdateCaseLend(att, applicant_id, customer_reference);
    }

    if(endpoint === 'UpdateCustomer') {
        r = executeUpdateCustomer(att, applicant_id, customer_reference)
    } 

    if(endpoint === 'UpdateCustomerUpdateCase') {
        r = executeUpdateCustomerUpdateCase(att, applicant_id, customer_reference)
    }

    if(endpoint === 'UpdateCustomerUpdateCaseReduced') {
        r = executeUpdateCustomerUpdateCaseReduced(att, applicant_id, customer_reference)
    } 

    if(endpoint === 'CreateNewProspectV2') {
        r = executeCreateNewProspectV2(att)
    }

    if(endpoint === 'CreateNewProspectV2Peregrine') {
        r = executeCreateNewProspectV2Peregrine(att)
    }

    if(endpoint === 'CreateNewProspectV2Lend') {
        r = executeCreateNewProspectV2Lend(att)
    }

    if(endpoint === 'CreateCaseJSON') {
        r = executeCreateCaseJSON(att, applicant_id, customer_reference)
    }

    if(endpoint === 'MasterCreateCaseJSON') {
        r = executeMasterCreateCaseJSON(att, applicant_id, customer_reference)
    }

    if(endpoint === 'CreateCaseJSONLend') {
        r = executeCreateCaseJSONLend(att, applicant_id, customer_reference)
    }

    if(endpoint === 'CreateCaseJSONPeregrine') {
        r = executeCreateCaseJSONPeregrine(att, applicant_id, customer_reference)
    }

}

/*
axios.post(config().base_url + endpoint, payload).then(function(response){
        console.log('response');
        console.log(response);
        // alert("Case Created");
    }).catch(function (error){
        console.log('caught error');
        console.log(error);
    });
    */
//}

export const getPaymentPlanDetailFormatted = (att) => {

    let freq = "M";
    if(att.frequency==='Half Year') freq="HY";
    if(att.frequency==='Quarterly') freq="Q";
    if(att.frequency==='Yearly') freq="Y";

        
    let ret = {
        "TotalLoan": twoDecimals(att.amount_financed),  // 1000
        "LoanTerm": (parseNum(att.payment_profile1) + parseNum(att.payment_profile2)) , // 2
        "LoanType": freq, // "M"
        "Rate": att.yield, // 6
        "Repayment": twoDecimals(att.one_payment), // 503.75
        "FirstPaymentDate": att.first_payment.split("-").reverse().join("-"), // DD-MM-YYYY => "2021-04-30"
        "LoanAgreementDate": att.start_date.split("-").reverse().join("-"), // DD-MM-YYYY => "2021-04-30"
        "PaymentPlanDetails": [
            /*
            {
                "Number": 1,
                "DueDate": "2021-04-30",
                "Amount": 503.75,
                "Principal": null,
                "Interest": null,
                "InterestRate": att.yeild,
                "RepaymentCalculated": null
            },
            {
                "Number": 2,
                "DueDate": "2021-05-30",
                "Amount": 503.76,
                "Principal": null,
                "Interest": null,
                "InterestRate": att.yeild,
                "RepaymentCalculated": null
            }
            */
        ]
    }

    // console.log(att);
    for(let i=0; i<100; i++) {
        // payments9, fixed9
        if(typeof( att['payments' + i] ) != 'undefined') {
            ret.PaymentPlanDetails.push({
                "Number": (i+1),
                "DueDate": att['matching_date' + i],
                "Amount": twoDecimals(att['payments' + i]),
                "Principal": null,
                "Interest": null,
                "InterestRate": att.yeild,
                "RepaymentCalculated": null
            });
        } else {
            break;
        }
    }

    return ret;
}

export const multiplyFields = (att, d, context=null) => {
    let a = [];
    let data = att;
    let customFieldsKey = 'CustomFields';

    if(context!==null) {
        console.log('multiplyFields context present (' + context + ')')
    }

    if(context=='EquipmentDetails') {
        customFieldsKey = 'EquipmentCustomField';
    }


    for (let i=0; i<100; i++) {

        let sufix = "__" + i;
        console.log('sufix=' + sufix);

        if(typeof att["mc1_DateOfBirth" + sufix] != 'undefined') att["mc1_DateOfBirth" + sufix] = convertDateForApi(att["mc1_DateOfBirth" + sufix]);

        if(typeof att["rrp" + sufix] != 'undefined')  att["rrp" + sufix] = convertToDouble(parseNum(att["rrp" + sufix]));
        if(typeof att["net_price" + sufix] != 'undefined')  att["net_price" + sufix] = convertToDouble(parseNum(att["net_price" + sufix]));

        let newObject = JSON.parse(JSON.stringify(d)); // deep copy of description object
        let keysFound = 0;
        
        Object.keys(newObject).forEach(e => { 
            let key = e;
            let value = newObject[e];
            
            // console.log('checking key ' + key);

            let dataKey = (value + sufix).replace('att.', ''); 
            let getActualValue = data[dataKey];
            if(typeof getActualValue != 'undefined') {
                keysFound ++;
                newObject[key] = getActualValue;
            } else {
                newObject[key] = null;
            }
        });

        if(keysFound > 0) {
            // console.log('keys found, adding object');
            newObject[customFieldsKey] = [];

            if(context=='EquipmentDetails') {

                if(data['sn' + sufix])                  { newObject[customFieldsKey].push({ CustomFieldNo: 1, CustomFieldValue: data['sn' + sufix], key: 'serial number' }) }
                if(data['reg_number' + sufix])          { newObject[customFieldsKey].push({ CustomFieldNo: 2, CustomFieldValue: data['reg_number' + sufix], key: 'reg number' }) }
                if(data['date_of_registraion' + sufix]) { newObject[customFieldsKey].push({ CustomFieldNo: 3, CustomFieldValue: data['date_of_registraion' + sufix], key: 'date of reg.' }) }
                if(data['current_miles' + sufix])       { newObject[customFieldsKey].push({ CustomFieldNo: 4, CustomFieldValue: data['current_miles' + sufix], key: 'current miles' }) }
                if(data['non_vatable_cost' + sufix])    { newObject[customFieldsKey].push({ CustomFieldNo: 5, CustomFieldValue: data['non_vatable_cost' + sufix], key: 'non vatable cost' }) }
                if(data['road_fund' + sufix])           { newObject[customFieldsKey].push({ CustomFieldNo: 6, CustomFieldValue: data['road_fund' + sufix], key: 'road fund' }) }
                if(data['equipment' + sufix])           { newObject[customFieldsKey].push({ CustomFieldNo: 7, CustomFieldValue: data['equipment' + sufix], key: 'equip' }) }
                if(data['total_cost_2' + sufix])        { newObject[customFieldsKey].push({ CustomFieldNo: 8, CustomFieldValue: data['total_cost_2' + sufix], key: 'total cost 2' }) }
                if(data['sup_name' + sufix])            { newObject[customFieldsKey].push({ CustomFieldNo: 9, CustomFieldValue: data['sup_name' + sufix], key: 'sup name' }) }
                if(data['sup_phone' + sufix])            { newObject[customFieldsKey].push({ CustomFieldNo: 10, CustomFieldValue: data['sup_phone' + sufix], key: 'sup phone' }) }
                if(data['sup_email' + sufix])            { newObject[customFieldsKey].push({ CustomFieldNo: 11, CustomFieldValue: data['sup_email' + sufix], key: 'sup email' }) }
                if(data['sup_contact_name' + sufix])            { newObject[customFieldsKey].push({ CustomFieldNo: 12, CustomFieldValue: data['sup_contact_name' + sufix], key: 'sup contact name' }) }
                if(data['sup_business_address1' + sufix])            { newObject[customFieldsKey].push({ CustomFieldNo: 13, CustomFieldValue: data['sup_business_address1' + sufix], key: 'sup business address1' }) }
                if(data['sup_business_address2' + sufix])            { newObject[customFieldsKey].push({ CustomFieldNo: 14, CustomFieldValue: data['sup_business_address2' + sufix], key: 'sup business address2' }) }
                if(data['sup_business_address3' + sufix])            { newObject[customFieldsKey].push({ CustomFieldNo: 15, CustomFieldValue: data['sup_business_address3' + sufix], key: 'sup business address3' }) }
                if(data['sup_business_address4' + sufix])            { newObject[customFieldsKey].push({ CustomFieldNo: 16, CustomFieldValue: data['sup_business_address4' + sufix], key: 'sup business address4' }) }
                if(data['sup_postcode' + sufix])            { newObject[customFieldsKey].push({ CustomFieldNo: 17, CustomFieldValue: data['sup_postcode' + sufix], key: 'sup postcode' }) }
                if(data['sup_mobile' + sufix])            { newObject[customFieldsKey].push({ CustomFieldNo: 18, CustomFieldValue: data['sup_mobile' + sufix], key: 'sup mobile' }) }
                if(data['sup_reg_number' + sufix])            { newObject[customFieldsKey].push({ CustomFieldNo: 19, CustomFieldValue: data['sup_reg_number' + sufix], key: 'sup reg number' }) }
                if(data['vat' + sufix])            { newObject[customFieldsKey].push({ CustomFieldNo: 20, CustomFieldValue: twoDecimals(data['vat' + sufix]), key: 'vat' }) }

            }
            else if(context=='MultiContacts') {

                if(data['mc1_CustomFieldValue1' + sufix]) { newObject[customFieldsKey].push({ CustomFieldNo: 1, CustomFieldValue: data['mc1_CustomFieldValue1' + sufix] }) }
                if(data['mc1_CustomFieldValue2' + sufix]) { newObject[customFieldsKey].push({ CustomFieldNo: 2, CustomFieldValue: data['mc1_CustomFieldValue2' + sufix] }) }
                if(data['mc1_CustomFieldValue3' + sufix]) { newObject[customFieldsKey].push({ CustomFieldNo: 3, CustomFieldValue: data['mc1_CustomFieldValue3' + sufix] }) }
                if(data['mc1_CustomFieldValue4' + sufix]) { newObject[customFieldsKey].push({ CustomFieldNo: 4, CustomFieldValue: data['mc1_CustomFieldValue4' + sufix] }) }
                if(data['mc1_CustomFieldValue5' + sufix]) { newObject[customFieldsKey].push({ CustomFieldNo: 5, CustomFieldValue: data['mc1_CustomFieldValue5' + sufix] }) }
                if(data['mc1_CustomFieldValue6' + sufix]) { newObject[customFieldsKey].push({ CustomFieldNo: 6, CustomFieldValue: data['mc1_CustomFieldValue6' + sufix] }) }
                if(data['mc1_CustomFieldValue7' + sufix]) { newObject[customFieldsKey].push({ CustomFieldNo: 7, CustomFieldValue: data['mc1_CustomFieldValue7' + sufix] }) }
                if(data['mc1_CustomFieldValue8' + sufix]) { newObject[customFieldsKey].push({ CustomFieldNo: 8, CustomFieldValue: data['mc1_CustomFieldValue8' + sufix] }) }
                if(data['mc1_CustomFieldValue9' + sufix]) { newObject[customFieldsKey].push({ CustomFieldNo: 9, CustomFieldValue: data['mc1_CustomFieldValue9' + sufix] }) }
                if(data['mc1_CustomFieldValue10' + sufix]) { newObject[customFieldsKey].push({ CustomFieldNo: 10, CustomFieldValue: data['mc1_CustomFieldValue10' + sufix] }) }

            } else {

                if(data['mc1_CustomFieldValue1' + sufix]) { newObject[customFieldsKey].push({ CustomFieldNo: 1, CustomFieldValue: data['mc1_CustomFieldValue1' + sufix] }) }
                if(data['mc1_CustomFieldValue2' + sufix]) { newObject[customFieldsKey].push({ CustomFieldNo: 2, CustomFieldValue: data['mc1_CustomFieldValue2' + sufix] }) }
                if(data['mc1_CustomFieldValue3' + sufix]) { newObject[customFieldsKey].push({ CustomFieldNo: 3, CustomFieldValue: data['mc1_CustomFieldValue3' + sufix] }) }
                if(data['mc1_CustomFieldValue4' + sufix]) { newObject[customFieldsKey].push({ CustomFieldNo: 4, CustomFieldValue: data['mc1_CustomFieldValue4' + sufix] }) }
                if(data['mc1_CustomFieldValue5' + sufix]) { newObject[customFieldsKey].push({ CustomFieldNo: 5, CustomFieldValue: data['mc1_CustomFieldValue5' + sufix] }) }
                if(data['mc1_CustomFieldValue6' + sufix]) { newObject[customFieldsKey].push({ CustomFieldNo: 6, CustomFieldValue: data['mc1_CustomFieldValue6' + sufix] }) }
                if(data['mc1_CustomFieldValue7' + sufix]) { newObject[customFieldsKey].push({ CustomFieldNo: 7, CustomFieldValue: data['mc1_CustomFieldValue7' + sufix] }) }
                if(data['mc1_CustomFieldValue8' + sufix]) { newObject[customFieldsKey].push({ CustomFieldNo: 8, CustomFieldValue: data['mc1_CustomFieldValue8' + sufix] }) }
                if(data['mc1_CustomFieldValue9' + sufix]) { newObject[customFieldsKey].push({ CustomFieldNo: 9, CustomFieldValue: data['mc1_CustomFieldValue9' + sufix] }) }
                if(data['mc1_CustomFieldValue10' + sufix]) { newObject[customFieldsKey].push({ CustomFieldNo: 10, CustomFieldValue: data['mc1_CustomFieldValue10' + sufix] }) }

            }

            a.push(newObject);
        } else {
            // console.log('keys not found, break');
            break;
        }
    }

    // console.log(a);
    return a;
}

export const toTitleCase = (str) => {
    return str.replace(
        /\w\S*/g,
        function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
}

export const findCustomField = (fields, key) =>
{
    if(Array.isArray(fields)) {
    for(let i=0; i<fields.length; i++) {
        let entry = fields[i];
            if(entry.CustomFieldNo==key) {
                return entry.CustomFieldValue;
            }
        }
    }
    return "";
}

export const orderResult = (objs, field, direction='asc') => {

    if(objs.length==0) {
        console.log("Sorting failed, empty set");
        return objs;
    }
    if(typeof objs[0][field] == 'undefined') {
        console.log("Sorting failed, key not found");
        return objs;
    }
    if(direction==='asc') {
        objs.sort((a, b) => a[field].localeCompare(b[field]));
    } else {
        objs.sort((b, a) => a[field].localeCompare(b[field]));
    }
    return objs;
}

export const parseNum = (field) => {
    if(typeof field === 'string') {
        field = field.replace(/,/g, "")
        // field = field.replace(',', '');
    }
    let n = Number(field);
    return (n) ? n : 0;
}