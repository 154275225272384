import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { currency } from '../Utils';
import Loading from '../components/Loading';
import { config } from '../Configurator'

class CasesRecent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            entries: [],
            classResolve: [],
            applicant_id: null,
            loading: true
        }
    }

    componentDidMount() {
        this.setState({applicant_id: this.props.id});
        this.load();
    }

    load() {
        axios.post(config().base_url +  'GetCaseAssessorByCaseJSON', {
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            },
            "jobCode": this.props.id
        })
        .then(response => {
            let assessor_ids = config().assessor_questions;
            let classResolve = this.state.classResolve;

            let resp = response.data.d.Records.filter(function(itm){
                return assessor_ids.includes(itm.Qno);
            })
            for(let i=0; i<resp.length; i++) {

                let qno = resp[i].Qno; 
                let answer_key = resp[i]['Answer'];
                if(answer_key && typeof resp[i]['AnswerList'] == 'object') {
                    if (resp[i]['AnswerList'][answer_key]=="No") {
                        classResolve[qno] = 'text-danger';
                    } else {
                        classResolve[qno] = 'text-success';
                    }    
                }
            }
            this.setState({loading: false, entries: resp, classResolve: classResolve });
        })
        .catch(error => {
            console.log(error);
            this.setState({loading: false});
        });

    }

    update (qno, answer) {
        
        let payload = this.updateCaseAssessorPayload(qno, answer);
        let classResolve = this.state.classResolve;

        for(let i=0; i<this.state.entries.length; i++ ) {
            if(this.state.entries[i]['Qno']==qno) {
                let answer_key = this.state.entries[i]['Answer'];

                if(answer_key && typeof this.state.entries[i]['AnswerList'] == 'object') {
                    if (this.state.entries[i]['AnswerList'][answer_key]=="No") {
                        classResolve[qno] = 'text-danger';
                    } else {
                        classResolve[qno] = 'text-success';
                    }    
                }

            }
        }


        this.setState({classResolve: classResolve});

        axios.post(config().base_url + 'UpdateCaseAssessorJSON', payload).then(function (response) {
            if(response.data.d.EnquiryResult.SuccessCode==0) {
                // ok
            }
        }).catch(function (error){
            console.log('caught error');
            console.log(error);
        });

    }

    updateCaseAssessorPayload = (qno, answer) => {

        let questions = this.state.entries;
        let ret = {
            "jobCode": this.state.applicant_id,
            "multiRecordList": [{"questions": questions}],
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            }
        }
        // modify payload with answers 
        for(let i=0; i<ret.multiRecordList[0].questions.length; i++) {
            if(ret.multiRecordList[0].questions[i].Qno==qno) {
                ret.multiRecordList[0].questions[i].Answer = answer;
            }
        }
        return ret;
    }

    handleChange = ({ target }) => {
        this.update(target.name.replace('entry_', ''), target.value);
    }

    render() {
        const { entries, loading, classResolve } = this.state;
        return (
            <div>
                <div className="row">
                    <div className="col-12 mt-3">
                        
                        {
                            loading ? <Loading></Loading> : null
                        }
                        <div className="card mb-3">
                            <div className="card-body">
                            {
                                entries.length ?
                                entries.map(entry => {
                                    return <div key={ entry.JobReference }>
                                        <div className="row mb-3">
                                        <div className="col-9"><strong>{entry.Question}</strong></div>
                                        <div className="col-3">

                                        { entry.QuestionType==='Data Table' ? 

                                            <select className={`form-control-condensed color-dropdown ${ classResolve[entry.Qno] }`} onChange={this.handleChange} name={'entry_' + entry.Qno} value={entry.Answer} >
                                                { entry.AnswerList.map(({ value, label }, index) => <option value={index} > {entry.AnswerList[index]} </option>)}
                                            </select> : null }

                                        { entry.QuestionType==='Text' ?
                                            <input type="text" className="form-control" onChange={this.handleChange} name={'entry_' + entry.Qno} value={entry.Answer} /> : null }
                                        </div>
                                        </div>
                                    </div>
                                }) : 
                                null
                            }
                            </div>   
                        </div> 
                    </div>
                </div>
                
            </div>
        )
    }

}

export default CasesRecent;