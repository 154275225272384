import React, { Component } from "react";
import Navbar from '../../components/Navbar';
import axios from 'axios';
import { Link } from 'react-router-dom';

class ArticleIndex extends Component {

    constructor() {
        super();
        this.state = { 
            loading: true,
            articles: []
        };
    }

    getData()
    {
        return this.props.data;
    }

    componentDidMount() { 

        axios.get('http://evelocity.info/projects/articles-api/public/api/articles')
        .then(response => {
            this.setState({ articles: response.data.data })
        })
        .catch(error => {
            alert("Error reading articles");
        });

    }

      render() {
        const { articles, loading } = this.state;
        return (
            <div>
            <Navbar />

            <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col-md-12">
                        <h2>Articles Index</h2>

                        <table className="table table-stripped">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Short</th>
                                    <th>Published</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                articles.length ?
                                articles.map(entry => {
                                    return <tr key={entry.id}>
                                        <td>{ entry.title }</td>
                                        <td>{ entry.short }</td>
                                        <td>{ entry.published_on }</td>
                                        <td>{ entry.status }</td>
                                        <td><Link to={ '/articles/edit/' + entry.id } className="btn-more"></Link></td>
                                    </tr> 
                                }) : 
                                null
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        );
                                
}
}

export default ArticleIndex;