import React from 'react';
import CasesTable from '../components/CasesTable';
import Navbar from '../components/Navbar';

export default function Cases() {
    return (
        <div>
            <Navbar />

            <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col-md-12">
                        {/* <h2>Cases</h2> */}
                        <CasesTable />
                    </div>
                </div>
            </div>


        </div>
    );
}