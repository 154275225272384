import { utils } from '@flipbyte/formik-json';
import _ from 'lodash';
import React from 'react';
import { changeHandler, joinNames } from '../utils0'
import MaskedInput from 'react-text-mask'

const CustomDate = ({ config, formik, value = '', error }) => {
    const {
        name,
        label,
        type,
        attributes,
        fieldType,
        defaultValue,
        icon,
        labelClass = '',
        fieldClass = 'form-control',
        formGroupClass = 'form-group'
    } = config;

    const { setFieldValue, handleChange, handleBlur } = formik;
    const isInputGroup = icon ? true : false;
    const currentValue = value;

    return (
        <React.Fragment>
            
            <MaskedInput

                id={ name }
                name={ name }
                type={ fieldType }
                tabIndex={ (attributes && attributes.tabIndex) ? attributes.tabIndex : '' } 
                className={ fieldClass + ( error ? ' is-invalid ' : '' ) }
                value={ currentValue }
                onChange={ changeHandler.bind(this, handleChange, formik, config) }
                onBlur={ handleBlur }
                validation={ (attributes && attributes.validation) ? attributes.validation : '' }

                mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}

                placeholder="DD-MM-YYYY"
                guide={false}

                />
            
        </React.Fragment>
    );
}

export default CustomDate; 