import { formDataLoop } from './formDataLoop';

let formData = {
  "id": "my-new-form",
  "label": "My New Form",
  "type": "container",
  "renderer": "form",
  "elements": {
    "main": {
      "type": "container",
      "renderer": "div",
      "htmlClass": "row",
      "elements": {

        "heading_top": {
          "name": "heading_top", 
          "type": "field",
          "renderer": "inner-text",
          "as": "h2",
          "htmlClass": 'form-group-full',
          'defaultValue': "Business Details"               
        },

        "name": {
          "name": "attributes.name",
          "label": "Name",
          "type": "field",
          "renderer": "text",
          "fieldType": "text"
        },

        "main_contact": {
          "name": "attributes.main_contact",
          "label": "Main contact",
          "type": "field",
          "renderer": "text",
          "fieldType": "text"
        },

        "registered_number": {
          "name": "attributes.registered_number",
          "label": "Registered number (if Limited)",
          "type": "field",
          "renderer": "text",
          "fieldType": "text"
        },

        "email": {
          "name": "attributes.email",
          "label": "Email",
          "type": "field",
          "renderer": "text",
          "fieldType": "text"
        },

        "trading_address": {
          "name": "attributes.trading_address",
          "label": "Trading Address",
          "type": "field",
          "renderer": "text",
          "fieldType": "text"
        },

        "telephone": {
          "name": "attributes.telephone",
          "label": "Telephone",
          "type": "field",
          "renderer": "text",
          "fieldType": "text"
        },

        "mobile": {
          "name": "attributes.mobile",
          "label": "Mobile",
          "type": "field",
          "renderer": "text",
          "fieldType": "text"
        },

        // ---------------------------------------


        "heading_type_of_business": {
          "name": "heading_top", 
          "type": "field",
          "renderer": "inner-text",
          "as": "h2",
          "htmlClass": 'form-group-full',
          'defaultValue': "Type of Business"               
        },

        "type_of_business": {
          "name": "attributesnpm .type_of_business",
          "label": "",
          "type": "field",
          "renderer": "textarea",
          "fieldType": "textarea"
        },

        // ---------------------------------------


        // ---------------------------------------


      }
    },
  }
};

let button = {
  "save": {
    "type": "field",
    "renderer": "button",
    "label": " ",
    "name": "save",
    "disabled": false,
    "content": "Submit",
    "fieldClass": "btn btn-dark btn-block",
    "buttonType": "submit"
  }
};

// formData.data.elements.main.elements.save.content= "ZZZRA";
// formData.elements.main.elements.save.content = "SUBMIT!";
formData.elements.main.elements = { ...formData.elements.main.elements, ...formDataLoop, ...button }

//console.log('---generated json---');
//console.log(JSON.stringify(formData));
//console.log('--------------------');

export { formData };