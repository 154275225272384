import axios from 'axios';
import { config } from '../Configurator'
import { multiplyFields, convertDateForApi, convertToDouble, dropLocalStorageData } from '../Utils'

const endpoint = "CreateCaseJSON";
const endpoint2 = "GetCaseAssessorByCaseJSON";
const endpoint3 = "UpdateCaseAssessorJSON";

export const executeMasterCreateCaseJSON = (att, applicant_id) => {


    let payload_1 = createCasePayload(att, applicant_id);
    // console.log(payload_1);
    // return false;

    axios.post(config().base_url + endpoint, payload_1).then(function (response) {
        if(response.data.d.EnquiryResult.SuccessCode==0) {

            applicant_id = response.data.d.Value;

            axios.post(config().base_url + endpoint2, getCaseAssessorPayload(applicant_id)).then(function (response) {

                if(response.data.d.EnquiryResult.SuccessCode==0) {
                    // #3 if everything okay update case assessor 
                    axios.post(config().base_url + endpoint3, updateCaseAssessorPayload(att, applicant_id, response.data)).then(function (response) {
                        if(response.data.d.EnquiryResult.SuccessCode==0) {
                            dropLocalStorageData();
                            window.location.href = "/case/" + applicant_id;
                        }
                    }).catch(function (error){
                        console.log('caught error');
                        console.log(error);
                    });

                }
            }).catch(function (error){
                console.log('caught error');
                console.log(error);
            });
            
        }
    }).catch(function (error) {
        console.log('caught error');
        console.log(error);
    });

}


const createCasePayload = (att, applicant_id, customer_reference) => {

    return {

        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        },
        "CaseType": "LoansMort",
        "CaseParams": {
            "Params": {
                "customerCode": customer_reference,
                "matterType": att.matterType,
                "provider": att.provider,
                "providerProduct": att.providerProduct,
                "LoanType": att.LoanType,
                "productCode": att.productCode,
                "providerRef": att.providerRef,
                "totalLoan": att.totalLoan,
                "term": att.terms,
                "termPeriod": "M",
                "InterestMonthly": att.InterestMonthly,
                "UnderWriter": att.UnderWriter,
                "Processor": att.Processor,
                "UserID": att.UserID,
                "AdditionalUser1": att.AdditionalUser1,
                "AdditionalUser2": att.AdditionalUser2,
                "Team": att.Team,
                "source": att.source,
                "SourceEmail": att.SourceEmail,
                "IsSourceNeedToCreate": "false",
                "SourceAddress": {
                    "houseName": att.SourceAddress_houseName,
                    "houseNumber": att.SourceAddress_houseNumber,
                    "address1": att.SourceAddress_address1,
                    "address2": att.SourceAddress_address2,
                    "address3": att.SourceAddress_address3,
                    "address4": att.SourceAddress_address4,
                    "postcode": att.SourceAddress_postcode
                },
                "sourceref": att.sourceref,
                "moneyfor": att.moneyfor,
                "sourceAffiliate": att.sourceAffiliate,
                "sourceInitialFeePerc": att.sourceInitialFeePerc,
                "sourceInitialFee": att.sourceInitialFee,
                "affiliateInitialFeePerc": att.affiliateInitialFeePerc,
                "affiliateInitialFee": att.affiliateInitialFee,
                "ExitFeePerc": att.ExitFeePerc,
                "ExitFee": att.ExitFee,
                "InitialFeePerc": att.InitialFeePerc,
                "InitialFee": att.InitialFee,
                "caseStatus": att.caseStatus,
                "currentAddressYears": att.currentAddressYears,
                "currentAddressMonths": att.currentAddressMonths,
                "app1PreviousAddress": [{
                    "houseName": "",
                    "houseNumber": null,
                    "address1": "",
                    "address2": "",
                    "address3": "",
                    "address4": "",
                    "postcode": "",
                    "timeInYears": null,
                    "timeInMonths": null
                }],
                "DynamicFieldDetail": [{
                    "FieldNo": 1,
                    "FieldValue": att.dynamicData1
                },
                {
                    "FieldNo": 2,
                    "FieldValue": att.dynamicData2
                },
                {
                    "FieldNo": 3,
                    "FieldValue": att.dynamicData3
                },
                {
                    "FieldNo": 4,
                    "FieldValue": att.dynamicData4
                },
                {
                    "FieldNo": 5,
                    "FieldValue": att.dynamicData5
                },
                {
                    "FieldNo": 6,
                    "FieldValue": att.dynamicData6
                },
                {
                    "FieldNo": 7,
                    "FieldValue": att.dynamicData6
                },
                {
                    "FieldNo": 8,
                    "FieldValue": att.dynamicData8
                },
                {
                    "FieldNo": 9,
                    "FieldValue": att.dynamicData9
                },
                {
                    "FieldNo": 10,
                    "FieldValue": att.dynamicData9
                },
                {
                    "FieldNo": 11,
                    "FieldValue": att.dynamicData11
                },
                {
                    "FieldNo": 12,
                    "FieldValue": att.dynamicData12
                }
                ],
                "SecurityAddress": multiplyFields(att, {
                    "houseName": 'att.asset1_house_name',
                    "houseNumber": 'att.asset1_house_no',
                    "address1": 'att.asset1_addr1',
                    "address2": 'att.asset1_addr2',
                    "address3": 'att.asset1_addr3',
                    "address4": 'att.asset1_addr4',
                    "postcode": 'att.asset1_postal',
                    "CurrentValue": 'att.asset1_CurrentValue',
                    "SecuirtyType": 'att.asset1_securityType',
                    "SecuirtyDetails": 'att.asset1_securityDetails',
                    "SecuirtyCharge": 'att.asset1_SecurityCharge',
                    "SecurityTitleNumber": 'att.asset1_TitleNumber'
                }),
                "customerDetails": {
                    "customerCode": customer_reference,
                    "b2B": att.b2B,
                    "IsUpdateCustomerDetail" : true,
                    "AlternativeSalutation": att.AlternativeSalutation,
                    "title": att.title,
                    "firstName": att.firstName,
                    "middleName": "",
                    "surname": att.surname,
                    "houseName": att.houseName,
                    "houseNumber": att.houseNumber,
                    "address1": att.business_address1,
                    "address2": att.business_address2,
                    "address3": att.business_address3,
                    "address4": att.business_address4,
                    "postcode": att.business_postcode,
                    "Website" : att.Website,
                    "CustomerGroup": att.CustomerGroup,
                    "source": att.source,
                    "SourceEmail": att.SourceEmail,
                    "IsSourceNeedToCreate": "false",
                    "SourceAddress": {
                        "houseName": att.SourceAddress_houseName,
                        "houseNumber": att.SourceAddress_houseNumber,
                        "address1": att.SourceAddress_address1,
                        "address2": att.SourceAddress_address2,
                        "address3": att.SourceAddress_address3,
                        "address4": att.SourceAddress_address4,
                        "postcode": att.SourceAddress_postcode
                    },
                    "CompanyAddress": {
                        "address1": att.CompanyAddress_address1,
                        "address2": att.CompanyAddress_address2,
                        "address3": att.CompanyAddress_address3,
                        "address4": att.CompanyAddress_address4,
                        "address5": att.CompanyAddress_address5,
                        "postcode": att.CompanyAddress_postcode
                    },
                    "MultiContacts": multiplyFields(att, {
                        "CustomFields": [{
                            "CustomFieldNo": 1,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue1'
                        },
                        {
                            "CustomFieldNo": 2,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue2'
                        },
                        {
                            "CustomFieldNo": 3,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue3'
                        },
                        {
                            "CustomFieldNo": 4,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue4'
                        },
                        {
                            "CustomFieldNo": 5,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue5'
                        },
                        {
                            "CustomFieldNo": 6,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue6'
                        },
                        {
                            "CustomFieldNo": 7,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue7'
                        },
                        {
                            "CustomFieldNo": 8,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue8'
                        },
                        {
                            "CustomFieldNo": 9,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue9'
                        },
                        {
                            "CustomFieldNo": 10,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue10'
                        },
                        ],
                        "Firstname": 'att.mc1_Firstname',
                        "Surname": 'att.mc1_Surname',
                        // "Guarantor": 'att.mc1_Guarantor',
                        "Address1": 'att.mc1_address1',
                        "Address2": 'att.mc1_address2',
                        "Address3": 'att.mc1_address3',
                        "Address4": 'att.mc1_address4',
                        "PostCode": 'att.mc1_postcode',
                        "DirectPhone": 'att.mc1_telephone',
                        "Mobile": 'att.mc1_mobile',
                        "Email": 'att.mc1_email',
                        "Position": 'att.mc1_position',
                        "DateOfBirth": 'att.mc1_DateOfBirth'
                    },

                    ),
                    "telephone": att.telephone,
                    "mobile": att.mobile,
                    "dateofBirth": convertDateForApi(""),
                    "company": att.Company,
                    "email": att.Email,
                    "loanAmount": att.loanAmount,
                    "applicants": null,
                    "othername": att.othername,
                    "ninumber": att.ninumber,
                    "ContactMethod": [
                        ""
                    ],
                    "notes": att.notes,
                    "ClientStatus": att.ClientStatus,
                    "IsDeduplicationReq": true,
                    "BankDetail": [{
                        "BankName": att.BankName,
                        "AccountName": att.AccountName,
                        "AccountNumber": att.AccountNumber,
                        "SortCode": att.SortCode
                    }]
                },
                "EquipmentDetails": [ 
                    {
                        "ID": "",
                        "ModelName": "",
                        "ModelDescription": "",
                        "Manufacturer": "",
                        "ManufacturerYear": "",
                        "ListPrice": "",
                        "SalePrice": "",
                        "Vat": "",
                        "Condition": "",
                        "EquipmentCustomField": [
                            {
                                "CustomFieldNo": "1",
                                "CustomFieldValue": ""
                            },
                            {
                                "CustomFieldNo": "2",
                                "CustomFieldValue": ""
                            },
                            {
                                "CustomFieldNo": "3",
                                "CustomFieldValue": ""
                            },
                            {
                                "CustomFieldNo": "4",
                                "CustomFieldValue": ""
                            },
                            {
                                "CustomFieldNo": "5",
                                "CustomFieldValue": ""
                            },
                            {
                                "CustomFieldNo": "6",
                                "CustomFieldValue": ""
                            },
                            {
                                "CustomFieldNo": "7",
                                "CustomFieldValue": ""
                            },
                            {
                                "CustomFieldNo": "8",
                                "CustomFieldValue": ""
                            },
                            {
                                "CustomFieldNo": "9",
                                "CustomFieldValue": ""
                            },
                            {
                                "CustomFieldNo": "10",
                                "CustomFieldValue": ""
                            }
                        ],
                        "ForceDelete": null
                    }
                ],
                "otherApplicantDetails": [
                    {
                        "applicantNumber": 1,
                        "title": null,
                        "firstName": "",
                        "middleName": null,
                        "surname": null,
                        "houseName": null,
                        "houseNumber": null,
                        "address1": null,
                        "address2": null,
                        "address3": null,
                        "address4": null,
                        "postcode": null,
                        "telephone": null,
                        "mobile": null,
                        "sex": null,
                        "dateofBirth": convertDateForApi(''),
                        "email": null,
                        "maritalStatus": null,
                        "currentAddressYears": null,
                        "currentAddressMonths": null,
                        "currentAddressEstimatedValue": null,
                        "currentAddressPurchasePrice": null,
                        "previousAddress": [
                            {
                                "houseName": null,
                                "houseNumber": null,
                                "address1": "",
                                "address2": "",
                                "address3": "",
                                "address4": "",
                                "postcode": "",
                                "timeInYears": null,
                                "timeInMonths": null
                            }
                        ],
                        "employmentDetails": {
                            "occupationType": null,
                            "employmentStatus": null,
                            "employerName": "",
                            "address1": "",
                            "address2": "",
                            "address3": "",
                            "address4": "",
                            "postcode": "",
                            "timeInYears": null,
                            "timeInMonths": null,
                            "firstIncome": null,
                            "secondIncome": null,
                            "otherIncomeDescription": null,
                            "partTimeHoursWorked": null
                        },
                        "previousEmploymentDetails": [
                            {
                                "occupationType": null,
                                "employmentStatus": null,
                                "employerName": "",
                                "address1": "",
                                "address2": "",
                                "address3": "",
                                "address4": "",
                                "postcode": "",
                                "timeInYears": null,
                                "timeInMonths": null,
                                "firstIncome": null,
                                "secondIncome": null,
                                "otherIncomeDescription": null,
                                "partTimeHoursWorked": null
                            }
                        ]
                    }
                ]
            }
        }

    }
}

const getCaseAssessorPayload = (applicant_id) => {
    return {
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
                  },
                  "jobCode": applicant_id
    }
}

const updateCaseAssessorPayload = (att, value, responseData) => {

    let ret = {
        "jobCode": value,
        "multiRecordList": [{"questions": responseData.d.Records}],
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        }
    }
    
    // modify payload with answers 
    let matches = 0;
    for(let i=0; i<ret.multiRecordList[0].questions.length; i++) {
        let qno = ret.multiRecordList[0].questions[i].Qno;
        // let code = ret.multiRecordList[i].Code;
        if(typeof att['caseAssessor' + qno] !== 'undefined') { // caseAssessor36
            matches ++;
            ret.multiRecordList[0].questions[i].Answer = att['caseAssessor' + qno];
        }
    }

    return ret;
}

export default {
    executeMasterCreateCaseJSON
};
