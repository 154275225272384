import React from "react";
import LoginBox from '../components/LoginBox';
import CssCustomizer from './../components/CssCustomizer';


export default function Login() {

    return (
    <div className="login-page">
        <CssCustomizer></CssCustomizer>
        <LoginBox></LoginBox>
    </div>
    );
}