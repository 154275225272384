export const config = (d) => {

    let domain = window.location.hostname;
    // let subdomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
    
    // let base_url = 'https://brokercrm.co.uk/Webservices/BrightOfficeAPI.asmx/';
    let base_url = 'https://www.myperegrinefinance.co.uk/Webservices/BrightOfficeAPI.asmx/';
    let logo = '/images/logo-reward-finance.svg';
 
    let visible_articles = true; // globaly enabled but it can be disabled for specific users/clients
    let visible_quick_quote_btn = true;
    let assessor_questions = [44,45,46,47,48,49,50,51,52,53,54,55,56,57];
    let cleanUpNames = [''];

    let forms = [

        

            // Cases
            // { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/8"   },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/14", "case_status": 'prospect', "b2b": true  },
            { "name": "Heads of Terms", "type": "link_case", "resource": "Heads of Terms", "case_status": 'prospect', "b2b": true   },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/18", "case_status": 'prospect', "b2b": false },
            { "name": "Heads of Terms", "type": "link_case", "resource": "Heads of Terms Ind", "case_status": 'prospect', "b2b": false  },
            { "name": "Proposal Form", "type": "link_case", "resource": "/clientworddocs/rewardfinance/Proposal Form.pdf", "case_status": 'prospect'  },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/7", "case_status": 'prospect', "b2b": true   },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/19", "case_status": 'prospect', "b2b": false  },
            { "name": "Asset and Liability Statement", "type": "link_case", "resource": "/clientworddocs/rewardfinance/Statement of Personal Assets and Liabilities - Draft 3 - 08.04.20.pdf", "case_status": 'prospect'  }, 
            { "name": "Etridge Letter", "type": "link_case", "resource": "/clientworddocs/rewardfinance/Etridge Letter to 3P Obligor.docx", "case_status": 'prospect'  },
            { "name": "Consent Letter", "type": "link_case", "resource": "/clientworddocs/rewardfinance/Letter of Consent (property).doc", "case_status": 'prospect'  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/9", "case_status": 'prospect', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/20", "case_status": 'prospect', "b2b": false  },
            { "name": "Risk Matrix", "type": "link_case", "resource": "Risk Matrix Portal", "case_status": 'prospect'  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/10", "case_status": 'prospect', "b2b": true  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/21", "case_status": 'prospect', "b2b": false  },
            { "name": "Credit Paper", "type": "link_case", "resource": "Credit Paper", "ReturnDocType": "DOC", "case_status": 'prospect', "b2b": false  },
            { "name": "Credit Paper", "type": "link_case", "resource": "Credit Paper -Business", "ReturnDocType": "DOC", "case_status": 'prospect', "b2b": true  },
            { "name": "Drawdown Request Form", "type": "link_case", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'prospect'  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/15", "case_status": 'prospect', "b2b": false  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/23", "case_status": 'prospect', "b2b": true  },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/13", "case_status": 'prospect', "b2b": false    },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/22", "case_status": 'prospect', "b2b": true  },
            // { "name": "Drawdown Request Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Capital - Drawdown Request Form.doc"  },
            //Live Cases
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/27", "case_status": 'live', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/28", "case_status": 'live', "b2b": false  },
            { "name": "Risk Matrix", "type": "link_case", "resource": "Risk Matrix Portal", "case_status": 'live',  },
            { "name": "Drawdown Request Form", "type": "link_case", "resource": "Drawdown Request Form", "ReturnDocType": "DOC",  "case_status": 'live'  },
            // Prospects
            { "name": "Heads of Terms Input", "type": "link_prospect", "resource": "/form/14"  },
            { "name": "Proposal Form Input", "type": "link_prospect", "resource": "/form/7"  },
       
       

    ];

    // OVERRIDES here:
    if(domain==='portal.myperegrinefinance.co.uk') {
        base_url = 'https://www.myperegrinefinance.co.uk/Webservices/BrightOfficeAPI.asmx/';
        logo = '/images/logo-reward-finance.svg';
        forms = [
            //Updated Packs & Docs 04052021
            // { "name": "Proposal Form", "type": "pdf_case", "resource": "Proposal Form" },
            // { "name": "Proposal Form Word", "type": "pdf_case", "resource": "Proposal Form", "ReturnDocType": "DOC"  },
            // { "name": "Unregulated Hire Purchase Pack", "LetterGroupCode": "Unregulated Hire Purchase Pack", "type": "pdf_case"  },
            // { "name": "Regulated Hire Purchase Pack", "LetterGroupCode": "Regulated Hire Purchase Pack", "type": "pdf_case"  },
            // { "name": "Unregulated Loan Pack", "LetterGroupCode": "Unregulated Loan Agreement Pack", "type": "pdf_case"  },
            // { "name": "Regulated Loan Pack", "LetterGroupCode": "Regulated Fixed Sum Loan Agreement Pack", "type": "pdf_case"  },
            // { "name": "Regulated Hire Pack - Reg Lease", "LetterGroupCode": "Regulated Hire Pack - Reg Lease", "type": "pdf_case"  },
            // { "name": "Unregulated Lease Pack", "LetterGroupCode": "Unregulated Finance Lease Pack", "type": "pdf_case"  },
            // { "name": "BNP DD Manidate", "type": "pdf_case", "resource": "BNP Direct Debit Mandate"  },
            // { "name": "DLL DD Manidate", "type": "pdf_case", "resource": "DLL Direct Debit Mandate"  },
            // { "name": "Aldermore DD Manidate", "type": "pdf_case", "resource": "Aldermore Direct Debit Mandate"  },
            // { "name": "Hitachi DD Manidate", "type": "pdf_case", "resource": "Hitachi Direct Debit Mandate January 2020"  },
            // { "name": "Guarantee and Indemnity Corporate", "type": "pdf_case", "resource": "Guarantee and Indemnity Corporate- PAFV8 0121" },
            // { "name": "Guarantee and Indemnity Individual", "type": "pdf_case", "resource": "Guarantee and Indemnity Individual- PAFV8 0121" },
            // { "name": "Landlords Waiver", "type": "pdf_case", "resource": "Landlords Waiver - PAFV8 0121" },
            // { "name": "Asset Inspection Form", "type": "pdf_case", "resource": "" },
            // { "name": "Equipment Schedule", "type": "pdf_case", "resource": "Equipment Schedule - PAFV8 0121" },
            // { "name": "Customer Mandate", "type": "pdf_case", "resource": "Customer Mandate - PAFV8 0121" },
            // { "name": "Pre-Contract Information Regulated Hire Purchase", "type": "pdf_case", "resource": "Pre-Contract Information Regulated Hire Purchase Agreement - PAFV8 0121" },
            // { "name": "Pre-Contract Information Regulated Loan", "type": "pdf_case", "resource": "Pre-Contract Information Regulated Loan Agreement - PAFV8 0121" },
            // { "name": "Pre-Contract Information Regulated Hire", "type": "pdf_case", "resource": "Pre-Contract Information Regulated Hire Agreement - PAFV8 0121" },
            // { "name": "Sale Agreement", "type": "pdf_case", "resource": "Sale Agreement - PAFV8 0121" },
            // { "name": "Schedule of Payments", "type": "pdf_case", "resource": "Schedule of Payments - PAFV8 0121" },

            //Updated Packs & Docs 02072021
            { "name": "Edit Details", "type": "link_case", "resource": "/form/28"},
            { "name": "Proposal Form Word", "type": "pdf_case", "resource": "Proposal Form", "ReturnDocType": "DOC"  },
            { "name": "Regulated Hire Purchase Agreement", "type": "pdf_case", "resource": "Regulated Hire Purchase Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Unregulated Hire Purchase Agreement", "type": "pdf_case", "resource": "Unregulated Hire Purchase Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Regulated Loan Agreement", "type": "pdf_case", "resource": "Regulated Fixed Sum Loan Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Unregulated Loan Agreement", "type": "pdf_case", "resource": "Unregulated Fixed Rate Loan Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Regulated Hire Agreement", "type": "pdf_case", "resource": "Regulated Hire Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Unregulated Finance Lease Agreement", "type": "pdf_case", "resource": "Unregulated Finance Lease PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Adequate Explanation – Regulated Hire Purchase", "type": "pdf_case", "resource": "Adequate Explanation Regulated Hire Purchase Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Adequate Explanation – Regulated Loan Agreement", "type": "pdf_case", "resource": "Adequate Explanation Regulated Loan Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Acceptance Certificate", "type": "pdf_case", "resource": "Acceptance Certificate - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Initial Disclosure", "type": "pdf_case", "resource": "Initial Disclosure and Client Consent to Search Form - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "BNP DD Manidate", "type": "pdf_case", "resource": "BNP Direct Debit Mandate", "ReturnDocType": "DOC"  },
            { "name": "DLL DD Manidate", "type": "pdf_case", "resource": "DLL Direct Debit Mandate", "ReturnDocType": "DOC"  },
            { "name": "Aldermore DD Manidate", "type": "pdf_case", "resource": "Aldermore Direct Debit Mandate", "ReturnDocType": "DOC"  },
            { "name": "Hitachi DD Manidate", "type": "pdf_case", "resource": "Hitachi Direct Debit Mandate January 2020", "ReturnDocType": "DOC"  },
            { "name": "Asset Inspection Form", "type": "pdf_case", "resource": "Asset Inspection Form - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Equipment Schedule", "type": "pdf_case", "resource": "Equipment Schedule - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Guarantee and Indemnity Corporate", "type": "pdf_case", "resource": "Guarantee and Indemnity Corporate- PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Guarantee and Indemnity Individual", "type": "pdf_case", "resource": "Guarantee and Indemnity Individual- PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Landlords Waiver", "type": "pdf_case", "resource": "Landlords Waiver - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Pre-Contract Information Regulated Hire Purchase", "type": "pdf_case", "resource": "Pre-Contract Information Regulated Hire Purchase Agreement - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Pre-Contract Information Regulated Loan", "type": "pdf_case", "resource": "Pre-Contract Information Regulated Loan Agreement - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Pre-Contract Information Regulated Hire", "type": "pdf_case", "resource": "Pre-Contract Information Regulated Hire Agreement - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Sale Agreement", "type": "pdf_case", "resource": "Sale Agreement - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Schedule of Payments", "type": "pdf_case", "resource": "Schedule of Payments - PAFV8 0121", "ReturnDocType": "DOC" },
            //Left but not on list provided
            // { "name": "Customer Mandate", "type": "pdf_case", "resource": "Customer Mandate - PAFV8 0121", "ReturnDocType": "DOC" },

        ];
    }

    if(domain==='peregrine.digitusrain.co.uk') {
        base_url = 'https://www.myperegrinefinance.co.uk/Webservices/BrightOfficeAPI.asmx/';
        logo = '/images/logo-reward-finance.svg';        
        forms = [
            //Updated Packs & Docs 04052021
            // { "name": "Proposal Form", "type": "pdf_case", "resource": "Proposal Form" },
            // { "name": "Proposal Form Word", "type": "pdf_case", "resource": "Proposal Form", "ReturnDocType": "DOC"  },
            // { "name": "Unregulated Hire Purchase Pack", "LetterGroupCode": "Unregulated Hire Purchase Pack", "type": "pdf_case"  },
            // { "name": "Regulated Hire Purchase Pack", "LetterGroupCode": "Regulated Hire Purchase Pack", "type": "pdf_case"  },
            // { "name": "Unregulated Loan Pack", "LetterGroupCode": "Unregulated Loan Agreement Pack", "type": "pdf_case"  },
            // { "name": "Regulated Loan Pack", "LetterGroupCode": "Regulated Fixed Sum Loan Agreement Pack", "type": "pdf_case"  },
            // { "name": "Regulated Hire Pack - Reg Lease", "LetterGroupCode": "Regulated Hire Pack - Reg Lease", "type": "pdf_case"  },
            // { "name": "Unregulated Lease Pack", "LetterGroupCode": "Unregulated Finance Lease Pack", "type": "pdf_case"  },
            // { "name": "BNP DD Manidate", "type": "pdf_case", "resource": "BNP Direct Debit Mandate"  },
            // { "name": "DLL DD Manidate", "type": "pdf_case", "resource": "DLL Direct Debit Mandate"  },
            // { "name": "Aldermore DD Manidate", "type": "pdf_case", "resource": "Aldermore Direct Debit Mandate"  },
            // { "name": "Hitachi DD Manidate", "type": "pdf_case", "resource": "Hitachi Direct Debit Mandate January 2020"  },
            // { "name": "Guarantee and Indemnity Corporate", "type": "pdf_case", "resource": "Guarantee and Indemnity Corporate- PAFV8 0121" },
            // { "name": "Guarantee and Indemnity Individual", "type": "pdf_case", "resource": "Guarantee and Indemnity Individual- PAFV8 0121" },
            // { "name": "Landlords Waiver", "type": "pdf_case", "resource": "Landlords Waiver - PAFV8 0121" },
            // { "name": "Asset Inspection Form", "type": "pdf_case", "resource": "" },
            // { "name": "Equipment Schedule", "type": "pdf_case", "resource": "Equipment Schedule - PAFV8 0121" },
            // { "name": "Customer Mandate", "type": "pdf_case", "resource": "Customer Mandate - PAFV8 0121" },
            // { "name": "Pre-Contract Information Regulated Hire Purchase", "type": "pdf_case", "resource": "Pre-Contract Information Regulated Hire Purchase Agreement - PAFV8 0121" },
            // { "name": "Pre-Contract Information Regulated Loan", "type": "pdf_case", "resource": "Pre-Contract Information Regulated Loan Agreement - PAFV8 0121" },
            // { "name": "Pre-Contract Information Regulated Hire", "type": "pdf_case", "resource": "Pre-Contract Information Regulated Hire Agreement - PAFV8 0121" },
            // { "name": "Sale Agreement", "type": "pdf_case", "resource": "Sale Agreement - PAFV8 0121" },
            // { "name": "Schedule of Payments", "type": "pdf_case", "resource": "Schedule of Payments - PAFV8 0121" },

            //Updated Packs & Docs 02072021
            { "name": "Edit Details", "type": "link_case", "resource": "/form/28"},
            { "name": "Proposal Form Word", "type": "pdf_case", "resource": "Proposal Form", "ReturnDocType": "DOC"  },
            { "name": "Regulated Hire Purchase Agreement", "type": "pdf_case", "resource": "Regulated Hire Purchase Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Unregulated Hire Purchase Agreement", "type": "pdf_case", "resource": "Unregulated Hire Purchase Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Regulated Loan Agreement", "type": "pdf_case", "resource": "Regulated Fixed Sum Loan Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Unregulated Loan Agreement", "type": "pdf_case", "resource": "Unregulated Fixed Rate Loan Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Regulated Hire Agreement", "type": "pdf_case", "resource": "Regulated Hire Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Unregulated Finance Lease Agreement", "type": "pdf_case", "resource": "Unregulated Finance Lease PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Adequate Explanation – Regulated Hire Purchase", "type": "pdf_case", "resource": "Adequate Explanation Regulated Hire Purchase Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Adequate Explanation – Regulated Loan Agreement", "type": "pdf_case", "resource": "Adequate Explanation Regulated Loan Agreement - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Acceptance Certificate", "type": "pdf_case", "resource": "Acceptance Certificate - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "Initial Disclosure", "type": "pdf_case", "resource": "Initial Disclosure and Client Consent to Search Form - PAFV8 0121", "ReturnDocType": "DOC"  },
            { "name": "BNP DD Manidate", "type": "pdf_case", "resource": "BNP Direct Debit Mandate", "ReturnDocType": "DOC"  },
            { "name": "DLL DD Manidate", "type": "pdf_case", "resource": "DLL Direct Debit Mandate", "ReturnDocType": "DOC"  },
            { "name": "Aldermore DD Manidate", "type": "pdf_case", "resource": "Aldermore Direct Debit Mandate", "ReturnDocType": "DOC"  },
            { "name": "Hitachi DD Manidate", "type": "pdf_case", "resource": "Hitachi Direct Debit Mandate January 2020", "ReturnDocType": "DOC"  },
            { "name": "Asset Inspection Form", "type": "pdf_case", "resource": "Asset Inspection Form - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Equipment Schedule", "type": "pdf_case", "resource": "Equipment Schedule - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Guarantee and Indemnity Corporate", "type": "pdf_case", "resource": "Guarantee and Indemnity Corporate- PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Guarantee and Indemnity Individual", "type": "pdf_case", "resource": "Guarantee and Indemnity Individual- PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Landlords Waiver", "type": "pdf_case", "resource": "Landlords Waiver - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Pre-Contract Information Regulated Hire Purchase", "type": "pdf_case", "resource": "Pre-Contract Information Regulated Hire Purchase Agreement - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Pre-Contract Information Regulated Loan", "type": "pdf_case", "resource": "Pre-Contract Information Regulated Loan Agreement - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Pre-Contract Information Regulated Hire", "type": "pdf_case", "resource": "Pre-Contract Information Regulated Hire Agreement - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Sale Agreement", "type": "pdf_case", "resource": "Sale Agreement - PAFV8 0121", "ReturnDocType": "DOC" },
            { "name": "Schedule of Payments", "type": "pdf_case", "resource": "Schedule of Payments - PAFV8 0121", "ReturnDocType": "DOC" },
            //Left but not on list provided
            // { "name": "Customer Mandate", "type": "pdf_case", "resource": "Customer Mandate - PAFV8 0121", "ReturnDocType": "DOC" },

        ];
    }

    if(domain==='portal.lendercrm.co.uk') {
        base_url = 'https://demo.lendercrm.co.uk/Webservices/BrightOfficeAPI.asmx/';
        logo = '/images/logo-brightOffice.png';
        visible_quick_quote_btn = false;
        forms = [
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/14", "b2b": true  },
            { "name": "Heads of Terms", "type": "pdf_case", "resource": "Heads of Terms", "b2b": true   },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/18", "b2b": false },
            { "name": "Heads of Terms", "type": "pdf_case", "resource": "Heads of Terms Ind", "b2b": false  },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/7", "b2b": true   },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/19", "b2b": false  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/9", "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/20", "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Portal"  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/10", "b2b": true  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/21", "b2b": false  },
            { "name": "Credit Paper", "type": "pdf_case", "resource": "Credit Paper", "ReturnDocType": "DOC"  },
            { "name": "Drawdown Request Form", "type": "pdf_case", "resource": "Drawdown Request Form", "ReturnDocType": "DOC"  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/15", "b2b": false  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/23", "b2b": true  },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/13", "b2b": false    },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/22", "b2b": true  },
            // { "name": "Drawdown Request Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Capital - Drawdown Request Form.doc"  },
            // Prospects
            { "name": "Heads of Terms Input", "type": "link_prospect", "resource": "/form/14"  },
            { "name": "Proposal Form Input", "type": "link_prospect", "resource": "/form/7"  },
        ];
    }

    if(domain==='portal.mylendology.co.uk') {
        base_url = 'https://www.mylendology.co.uk/Webservices/BrightOfficeAPI.asmx/';
        logo = '/images/logo-lendology.png';
        visible_quick_quote_btn = false;
        forms = [
            { "name": "Create Case", "type": "link_prospect", "resource": "/form/25"  },
            { "name": "Customer Status", "type": "link_prospect", "resource": "/form/26"  },
            { "name": "KYC Application", "type": "link_case", "resource": "/form/16"  },
            { "name": "1st Appt Letter", "type": "pdf_case", "resource": "1st Appt Letter New", "ReturnDocType": "DOC"  },
            { "name": "Case Status", "type": "link_case", "resource": "/form/27"  },
        ];
    }


    if(domain==='portal.mylendwell.co.uk') {
        base_url = 'https://www.mylendwell.co.uk/Webservices/BrightOfficeAPI.asmx/';
        logo = '/images/Lendwell_Logo.png';
        forms = [
            { "name": "Heads of Terms Input", "type": "link", "resource": "/form/8"  },
            { "name": "Heads of Terms", "type": "pdf", "resource": "Heads of Terms Template"  },
            { "name": "Proposal Form", "type": "pdf", "resource": "PAF - Proposal Form"  },
            { "name": "Asset and Liability", "type": "docx", "resource": "/clientworddocs/rewardfinance/Statement of Personal Assets and Liabilities (1).docx"  }, 
            { "name": "Etridge Letter", "type": "docx", "resource": "/clientworddocs/rewardfinance/Etridge Letter to 3P Obligor.docx"  },
            { "name": "Consent Letter", "type": "docx", "resource": "/clientworddocs/rewardfinance/Letter of Consent (property).doc"  },
            { "name": "Risk Matrix", "type": "pdf", "resource": "Risk Matrix"  },
            { "name": "Credit Report", "type": "pdf", "resource": "Credit Paper - Reward Capital"  },
        ];
    }

    if(domain==='portal.myrewardfinance.co.uk') {
        base_url = 'https://test.myrewardfinance.co.uk/Webservices/BrightOfficeAPI.asmx/';
        logo = '/images/logo-reward-finance.svg';
        visible_articles = false;
        visible_quick_quote_btn = false;
        cleanUpNames = ['calvin.dexter', 'damien.slingsby', 'dominic.sievewright', 'ian.lewis', 'james.mortimore', 'neil.mcgivern', 'nick.battersby', 'noel.haverly', 'peter.cromarty', 'rob.mercer'];
        assessor_questions = [44,45,46,47,48,49,50,51,52,53,54,55,56,57];
        forms = [
            // Cases
            // { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/8"  },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/14", "b2b": true  },
            { "name": "Heads of Terms", "type": "pdf_case", "resource": "Heads of Terms", "b2b": true   },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/18", "b2b": false },
            { "name": "Heads of Terms", "type": "pdf_case", "resource": "Heads of Terms Ind", "b2b": false  },
            { "name": "Proposal Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Proposal Form.pdf"  },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/7", "b2b": true   },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/19", "b2b": false  },
            { "name": "Asset and Liability Statement", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Statement of Personal Assets and Liabilities - Draft 3 - 08.04.20.pdf"  }, 
            { "name": "Etridge Letter", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Etridge Letter to 3P Obligor.docx"  },
            { "name": "Consent Letter", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Letter of Consent (property).doc"  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/9", "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/20", "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Portal"  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/10", "b2b": true  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/21", "b2b": false  },
            { "name": "Credit Paper", "type": "pdf_case", "resource": "Credit Paper", "ReturnDocType": "DOC", "b2b": false  },
            { "name": "Credit Paper", "type": "pdf_case", "resource": "Credit Paper -Business", "ReturnDocType": "DOC", "b2b": true  },
            { "name": "Drawdown Request Form", "type": "pdf_case", "resource": "Drawdown Request Form", "ReturnDocType": "DOC"  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/15", "b2b": false  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/23", "b2b": true  },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/13", "b2b": false    },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/22", "b2b": true  },
            // { "name": "Drawdown Request Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Capital - Drawdown Request Form.doc"  },
            // Prospects
            { "name": "Heads of Terms Input", "type": "link_prospect", "resource": "/form/14"  },
            { "name": "Proposal Form Input", "type": "link_prospect", "resource": "/form/7"  },
        ];
    }

    if(domain==='portalbeta.myrewardfinance.co.uk') {
        base_url = 'https://test.myrewardfinance.co.uk/Webservices/BrightOfficeAPI.asmx/';
        logo = '/images/logo-reward-finance.svg';
        visible_articles = false;
        visible_quick_quote_btn = false;
        cleanUpNames = ['calvin.dexter', 'damien.slingsby', 'dominic.sievewright', 'ian.lewis', 'james.mortimore', 'neil.mcgivern', 'nick.battersby', 'noel.haverly', 'peter.cromarty', 'rob.mercer'];
        assessor_questions = [44,45,46,47,48,49,50,51,52,53,54,55,56,57];
        forms = [
            // Cases
            // { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/8"   },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/14", "b2b": true  },
            { "name": "Heads of Terms", "type": "pdf_case", "resource": "Heads of Terms", "b2b": true   },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/18", "b2b": false },
            { "name": "Heads of Terms", "type": "pdf_case", "resource": "Heads of Terms Ind", "b2b": false  },
            { "name": "Proposal Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Proposal Form.pdf"  },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/7", "b2b": true   },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/19", "b2b": false  },
            { "name": "Asset and Liability Statement", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Statement of Personal Assets and Liabilities - Draft 3 - 08.04.20.pdf"  }, 
            { "name": "Etridge Letter", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Etridge Letter to 3P Obligor.docx"  },
            { "name": "Consent Letter", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Letter of Consent (property).doc"  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/9", "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/20", "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Portal"  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/10", "b2b": true  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/21", "b2b": false  },
            { "name": "Credit Paper", "type": "pdf_case", "resource": "Credit Paper", "ReturnDocType": "DOC", "b2b": false  },
            { "name": "Credit Paper", "type": "pdf_case", "resource": "Credit Paper -Business", "ReturnDocType": "DOC", "b2b": true  },
            { "name": "Drawdown Request Form", "type": "pdf_case", "resource": "Drawdown Request Form", "ReturnDocType": "DOC"  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/15", "b2b": false  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/23", "b2b": true  },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/13", "b2b": false    },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/22", "b2b": true  },
            // { "name": "Drawdown Request Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Capital - Drawdown Request Form.doc"  },
            // Prospects
            { "name": "Heads of Terms Input", "type": "link_prospect", "resource": "/form/14"  },
            { "name": "Proposal Form Input", "type": "link_prospect", "resource": "/form/7"  },
        ];
    }

    if(domain==='demo.jobcallout.co.uk') {
        base_url = 'https://demo.jobcallout.co.uk/Webservices/BrightOfficeAPI.asmx/';
        logo = '/images/logo-jobsdemo.png';
        visible_articles = false;
        visible_quick_quote_btn = false;
        forms = [
            // Cases
            // { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/8"   },
            { "name": "Job Update", "type": "link_case", "resource": "/form/14", "b2b": true  },
            // { "name": "Heads of Terms", "type": "pdf_case", "resource": "Heads of Terms", "b2b": true   },
            // { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/18", "b2b": false },
        ];
    }

    if(domain==='mint.digiusrain.co.uk') {
        base_url = 'https://mymintbridging.co.uk/Webservices/BrightOfficeAPI.asmx/';
        logo = '/images/logo-mint.png';
        visible_articles = false;
        visible_quick_quote_btn = false;
        let assessor_questions = [1,2,3,4,5,6,7,8,9];
        forms = [
            // Cases
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/8"  },
            { "name": "MPF Dev DIP", "type": "pdf_case", "resource": "	MPF Dev DIP Form"  },
            { "name": "Credit Report Input", "type": "link_case", "resource": "/form/10"  },
            // Prospects
            { "name": "Heads of Terms Input", "type": "link_prospect", "resource": "/form/8"  },
            { "name": "Proposal Form Input", "type": "link_prospect", "resource": "/form/7"  },
        ];
    }

    if(domain==='portal.digitusrain.co.uk') {
        base_url = 'https://brokercrm.co.uk/Webservices/BrightOfficeAPI.asmx/';
        logo = '/images/logo-brightOffice.png';
        visible_articles = false;
        visible_quick_quote_btn = false;
        forms = [
            // Cases
            // { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/8"   },
            { "name": "Make a Booking", "type": "link_case", "resource": "/form/14", "b2b": true  },
            { "name": "Print Certificate", "type": "pdf_case", "resource": "Heads of Terms", "b2b": true   },
            // { "name": "Drawdown Request Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Capital - Drawdown Request Form.doc"  },
            // Prospects
            { "name": "Heads of Terms Input", "type": "link_prospect", "resource": "/form/14"  },
            { "name": "Proposal Form Input", "type": "link_prospect", "resource": "/form/7"  },
        ];
    }

    if(domain==='crm.evelocity.info') {
        logo = '/images/logo-reward-finance.svg';
    }

    if(domain==='localhost') {
        cleanUpNames = [];
    }

    // if local storage is empty we are probably on the login page
    if(localStorage.getItem('bo_email')===null) {
        let data = {
            'base_url': base_url,
            'logo': logo
        }
        return data;
    }


    if( localStorage.getItem('bo_email') === 'srdjan.stojkovicapi' ||  localStorage.getItem('bo_email') === 'srdjan.stojkovic' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo.png',
            'navbarClass': 'dark', // dark or white
            'navIconClass': 'white', // dark or white
            'personName': 'Ollie Roby',
            'custom_css': './css/peregrine/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/profile.png',
            'customerLimitCode': 'C000696',
            'client_id': 1,
            'cleanUpNames': cleanUpNames,
            'nav_links': [
                ['/form/3', 'Form number 3'],
                ['/form/4', 'Form number 4'],
            ],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'broker.api' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-brightOffice.png',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Ollie Roby',
            'custom_css': './css/domain/portal.mymintbridging.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/profile.png',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [
                ['/form/11', 'Prospect Creation'],
                ['/form/8', 'Heads of Terms'],
                ['/form/7', 'Proposal Form'],
                ['/form/9', 'Risk Score'],
                ['/form/10', 'Credit Form'],
            ],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'peregrine.finance' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Ollie Roby',
            'custom_css': './css/domain/portal.myperegrinefinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/profile.png',
            'customerLimitCode': 'C000001',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'adam.spenceley' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo':  '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Adam Spenceley',
            'custom_css': './css/domain/portal.myperegrinefinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C000001',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'jaye.rhodes' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Jaye Rhodes',
            'custom_css': './css/domain/portal.myperegrinefinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C000001',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'wessex.resolutions' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-lendology.png',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Ollie Roby',
            'custom_css': './css/domain/portal.mylendology.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/profile.png',
            'customerLimitCode': 'C000001',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'mint.bridging' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-mint.png',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Ollie Roby',
            'custom_css': './css/domain/portal.mymintbridging.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/profile.png',
            'customerLimitCode': 'C000001',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [
                ['/form/12', 'Enquiry'],
            ],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'ellie.lister' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-lendology.png',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Ellie Lister',
            'custom_css': './css/domain/portal.mylendology.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C000001',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'philippa.cooke' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-lendology.png',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Philippa Cooke',
            'custom_css': './css/domain/portal.mylendology.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C000001',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'emma.lower' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-lendology.png',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Emma Lower',
            'custom_css': './css/domain/portal.mylendology.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C000001',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'lender.demo' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-brightOffice.png',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Michael Fairhurst',
            'custom_css': './css/domain/portal.lendercrm.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/mf.jpeg',
            'customerLimitCode': 'C000001',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [
                ['/form/3', 'Form number 3'],
            ],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'or.brokercrm' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-digitusrain.png',
            'navbarClass': 'dark', // dark or white
            'navIconClass': 'white', // dark or white
            'personName': 'Ollie Roby',
            'custom_css': './css/portal/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C000696',
            'cleanUpNames': cleanUpNames,
            'client_id': 2,
            'nav_links': [
                ['/form/3', 'Form number 3'],
                ['/form/4', 'Form number 4'],
                ['/form/5', 'Form number 5'],
                ['/form/6', 'Form number 6'],
            ],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'mike.newhouse' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Mike Newhouse',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'cleanUpNames': cleanUpNames,
            'client_id': 2,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'tom.newhouse' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Tom Newhouse',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'reward.test' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'reward.test',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portaltom.newhouse' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Tom Newhouse',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalchristine.daly' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Christine Daly',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalcassey.mcnulty' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Cassey McNulty',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalpaula.jones' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Paula Jones',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portallouise.donnelly' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Louise Donnelly',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalluke.drysdale' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Luke Drysdale',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalbethany.hunnebell' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Bethany Hunnebell',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalclaire.mitchell' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Claire Mitchell',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalchris.ibbetson' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Chris Ibbetson',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portaljohn.gallagher' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'John Gallagher',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portaltim.stafford' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Tim Stafford',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portaldavid.baddiel' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'David Baddiel',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalalan.sanderson' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Alan Sanderson',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portaljoanna.haigh' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Joanna Haigh',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalkaren.vincent' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Karen Vincent',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalrachel.lee' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Rachel Lee',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalgemma.wright' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Gemma Wright',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalmike.newhouse' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Mike Newhouse',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalnick.smith' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Nick Smith',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalkaren.paige' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Karen Paige',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalnick.battersby' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Nick Battersby',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalsteve.noble' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Steve Noble',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portaltom.flannery' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Tom Flannery',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portaldavid.jones' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'David Jones',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalclive.briggs' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Clive Briggs',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalmike.calvert' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Mike Calvert',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalrussell.holt' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Russell Holt',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalelizabeth.harrison' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Elizabeth Harrison',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalparisa.lian' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Parisa Lian',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portaldavid.harrop' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'David Harrop',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'portalsimon.micklethwaite' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Simon Micklethwaite',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'christine.daly' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Christine Daly',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'cassey.mcnulty' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Cassey McNulty',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'paula.jones' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Paula Jones',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'louise.donnelly' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Louise Donnelly',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'luke.drysdale' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Luke Drysdale',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'bethany.hunnebell' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Bethany Hunnebell',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'claire.mitchell' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Claire Mitchell',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'chris.ibbetson' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Chris Ibbetson',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'john.gallagher' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'John Gallagher',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'tim.stafford' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Tim Stafford',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'david.baddiel' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'David Baddiel',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'alan.sanderson' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Alan Sanderson',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'joanna.haigh' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Joanna Haigh',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'karen.vincent' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Karen Vincent',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'rachel.lee' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Rachel Lee',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'gemma.wright' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Gemma Wright',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'nick.smith' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Nick Smith',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'karen.paige' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Karen Paige',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'nick.battersby' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Nick Battersby',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'steve.noble' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Steve Noble',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'tom.flannery' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Tom Flannery',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'david.jones' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'David Jones',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'clive.briggs' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Clive Briggs',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'mike.calvert' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Mike Calvert',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'russell.holt' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Russell Holt',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'elizabeth.harrison' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Elizabeth Harrison',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'parisa.lian' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Parisa Lian',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'david.harrop' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'David Harrop',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'simon.micklethwaite' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-reward-finance.svg',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Simon Micklethwaite',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/team-corporate-1.jpg',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'reward.finance' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-cooperreed.png',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Ollie Roby',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/profile.png',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [
                ['/form/11', 'Prospect Creation'],
                ['/form/8', 'Heads of Terms'],
                ['/form/7', 'Proposal Form'],
                ['/form/9', 'Risk Score'],
                ['/form/10', 'Credit Form'],
            ],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'training.demo' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/logo-brightOffice.png',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Matt George',
            'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/matt.png',
            'customerLimitCode': 'C001286',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [],
            'assessor_questions': assessor_questions
        }
    }
    if( localStorage.getItem('bo_email') === 'lend.well' ) {
        return {
            'base_url': base_url,
            'forms': forms,
            'visible_articles': visible_articles,
            'visible_quick_quote_btn': visible_quick_quote_btn,
            'logo': '/images/Lendwell_Logo.png',
            'navbarClass': 'white', // dark or white
            'navIconClass': 'dark', // dark or white
            'personName': 'Michael Fairhurst',
            'custom_css': './css/domain/portal.mylendwell.co.uk/default.css',
            'personFunction': 'Portal Administrator',
            'personProfileImage': '/images/mf.jpeg',
            'customerLimitCode': 'C004995',
            'client_id': 2,
            'cleanUpNames': cleanUpNames,
            'nav_links': [
                ['/form/11', 'Prospect Creation'],
                ['/form/7', 'Proposal Form Input'],
                ['/form/8', 'Heads of Terms'],
                ['/form/9', 'Risk Score'],
                ['/form/10', 'Credit Form'],
            ],
            'assessor_questions': assessor_questions
        }
    }


   
    alert("No config for this user");
}
