import axios from 'axios';
import { config } from '../Configurator'
import { multiplyFields, convertDateForApi, convertToDouble, dropLocalStorageData } from '../Utils'

const endpoint = "CreateCaseJSON";
const endpoint2 = "GetCaseAssessorByCaseJSON";
const endpoint3 = "UpdateCaseAssessorJSON";

export const executeCreateCaseJSONLend = (att, applicant_id) => {


    let payload_1 = createCasePayload(att, applicant_id);
    // console.log(payload_1);
    // return false;

    axios.post(config().base_url + endpoint, payload_1).then(function (response) {
        if(response.data.d.EnquiryResult.SuccessCode==0) {

            applicant_id = response.data.d.Value;

            axios.post(config().base_url + endpoint2, getCaseAssessorPayload(applicant_id)).then(function (response) {

                if(response.data.d.EnquiryResult.SuccessCode==0) {
                    // #3 if everything okay update case assessor 
                    axios.post(config().base_url + endpoint3, updateCaseAssessorPayload(att, applicant_id, response.data)).then(function (response) {
                        if(response.data.d.EnquiryResult.SuccessCode==0) {
                            dropLocalStorageData();
                            window.location.href = "/case/" + applicant_id;
                        }
                    }).catch(function (error){
                        console.log('caught error');
                        console.log(error);
                    });

                }
            }).catch(function (error){
                console.log('caught error');
                console.log(error);
            });
            
        }
    }).catch(function (error) {
        console.log('caught error');
        console.log(error);
    });

}


const createCasePayload = (att, applicant_id, customer_reference) => {

    return {

        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        },
        "CaseType": "LoansMort",
        "CaseParams": {
            "Params": {
                "customerCode": applicant_id,
                "matterType": att.matterType,
                "provider": att.provider,
                "providerProduct": att.providerProduct,
                "LoanType": att.LoanType,
                "productCode": att.productCode,
                "providerRef": null,
                "totalLoan": att.totalLoan,
                "term": att.terms,
                "termPeriod": "M",
                "InterestMonthly": att.InterestMonthly,
                "UnderWriter": att.UnderWriter,
                "Processor": att.Processor,
                "UserID": null,
                "AdditionalUser1": att.AdditionalUser1,
                "AdditionalUser2": "",
                "Team": "",
                "source": att.source,
                "SourceEmail": "",
                "IsSourceNeedToCreate": "false",
                "SourceAddress": {
                    "houseName": "",
                    "houseNumber": null,
                    "address1": "",
                    "address2": "",
                    "address3": "",
                    "address4": "",
                    "postcode": ""
                },
                "sourceref": "",
                "moneyfor": "",
                "sourceAffiliate": null,
                "sourceInitialFeePerc": 0,
                "sourceInitialFee": 0,
                "affiliateInitialFeePerc": 0,
                "affiliateInitialFee": 0,
                "ExitFeePerc": null,
                "ExitFee": att.ExitFee,
                "InitialFeePerc": null,
                "InitialFee": att.InitialFee,
                "caseStatus": att.caseStatus,
                "currentAddressYears": "",
                "currentAddressMonths": null,
                "app1PreviousAddress": [{
                    "houseName": att.app1PrevioushouseName,
                    "houseNumber": att.app1PrevioushouseNumber,
                    "address1": att.app1Previousaddress1,
                    "address2": att.app1Previousaddress2,
                    "address3": att.app1Previousaddress3,
                    "address4": att.app1Previousaddress4,
                    "postcode": att.app1Previouspostcode,
                    "timeInYears": att.app1PrevioustimeInYears,
                    "timeInMonths": att.app1PrevioustimeInMonths
                }],
                "DynamicFieldDetail": [{
                    "FieldNo": 1,
                    "FieldValue": ""
                },
                {
                    "FieldNo": 2,
                    "FieldValue": att.dynamicData2
                },
                {
                    "FieldNo": 3,
                    "FieldValue": att.risk_rating
                },
                {
                    "FieldNo": 4,
                    "FieldValue": att.dynamicData4
                },
                {
                    "FieldNo": 5,
                    "FieldValue": att.dynamicData5
                },
                {
                    "FieldNo": 6,
                    "FieldValue": att.dynamicData6
                },
                {
                    "FieldNo": 8,
                    "FieldValue": ""
                },
                {
                    "FieldNo": 11,
                    "FieldValue": ""
                },
                {
                    "FieldNo": 12,
                    "FieldValue": ""
                }
                ],
                "SecurityAddress": multiplyFields(att, {
                    "houseName": 'att.asset1_house_name',
                    "houseNumber": 'att.asset1_house_no',
                    "address1": 'att.asset1_addr1',
                    "address2": 'att.asset1_addr2',
                    "address3": 'att.asset1_addr3',
                    "address4": 'att.asset1_addr4',
                    "postcode": 'att.asset1_postal',
                    "CurrentValue": 'att.asset1_CurrentValue',
                    "SecuirtyType": 'att.asset1_securityType',
                    "SecuirtyDetails": 'att.asset1_securityDetails',
                    "SecuirtyCharge": 'att.asset1_SecurityCharge',
                    "SecurityTitleNumber": 'att.asset1_TitleNumber'
                }),
                "customerDetails": {
                    "customerCode": applicant_id,
                    "b2B": "false",
                    "IsUpdateCustomerDetail" : true,
                    "AlternativeSalutation": att.AlternativeSalutation,
                    "title": att.title,
                    "firstName": att.firstName,
                    "middleName": "",
                    "surname": att.surname,
                    "houseName": "",
                    "houseNumber": "",
                    "address1": att.address1,
                    "address2": att.address2,
                    "address3": att.address3,
                    "address4": att.address4,
                    "postcode": att.postcode,
                    "source": att.source,
                    "SourceEmail": "",
                    "IsSourceNeedToCreate": "false",
                    "SourceAddress": {
                        "houseName": "",
                        "houseNumber": null,
                        "address1": "",
                        "address2": "",
                        "address3": "",
                        "address4": "",
                        "postcode": ""
                    },
                    "CompanyAddress": {
                        "address1": att.business_address1,
                        "address2": att.business_address2,
                        "address3": att.business_address3,
                        "address4": att.business_address4,
                        "postcode": att.business_postcode
                    },
                    "MultiContacts": multiplyFields(att, {
                        "CustomFields": [{
                            "CustomFieldNo": 1,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue1'
                        },
                        {
                            "CustomFieldNo": 2,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue2'
                        },
                        {
                            "CustomFieldNo": 3,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue3'
                        },
                        {
                            "CustomFieldNo": 6,
                            "CustomFieldValue": 'att.mc1_CustomFieldValue6'
                        }
                        ],
                        "Firstname": 'att.mc1_Firstname',
                        "Surname": 'att.mc1_Surname',
                        // "Guarantor": 'att.mc1_Guarantor',
                        "Address1": 'att.mc1_address1',
                        "Address2": 'att.mc1_address2',
                        "Address3": 'att.mc1_address3',
                        "Address4": 'att.mc1_address4',
                        "PostCode": 'att.mc1_postcode',
                        "DirectPhone": 'att.mc1_telephone',
                        "Mobile": 'att.mc1_mobile',
                        "Email": 'att.mc1_email',
                        "Position": 'att.mc1_position',
                        "DateOfBirth": 'att.mc1_DateOfBirth'
                    },

                    ),
                    "telephone": att.telephone,
                    "mobile": att.mobile,
                    "dateofBirth": convertDateForApi(""),
                    "company": att.Company,
                    "email": att.Email,
                    "loanAmount": att.loanAmount,
                    "applicants": null,
                    "othername": "",
                    "ninumber": "",
                    "ContactMethod": [
                        ""
                    ],
                    "notes": "",
                    "ClientStatus": "Customer",
                    "IsDeduplicationReq": true,
                    "BankDetail": [{
                        "BankName": "",
                        "AccountName": "",
                        "AccountNumber": "",
                        "SortCode": ""
                    }]
                },
                "otherApplicantDetails": [
                    {
                        "applicantNumber": 1,
                        "title": null,
                        "firstName": "",
                        "middleName": null,
                        "surname": null,
                        "houseName": null,
                        "houseNumber": null,
                        "address1": null,
                        "address2": null,
                        "address3": null,
                        "address4": null,
                        "postcode": null,
                        "telephone": null,
                        "mobile": null,
                        "sex": null,
                        "dateofBirth": convertDateForApi(''),
                        "email": null,
                        "maritalStatus": null,
                        "currentAddressYears": att.currentAddressYears,
                        "currentAddressMonths": att.currentAddressMonths,
                        "currentAddressEstimatedValue": null,
                        "currentAddressPurchasePrice": null,
                        "previousAddress": [
                            {
                                "houseName": null,
                                "houseNumber": null,
                                "address1": "",
                                "address2": "",
                                "address3": "",
                                "address4": "",
                                "postcode": "",
                                "timeInYears": null,
                                "timeInMonths": null
                            }
                        ],
                        "employmentDetails": {
                            "occupationType": null,
                            "employmentStatus": null,
                            "employerName": "",
                            "address1": "",
                            "address2": "",
                            "address3": "",
                            "address4": "",
                            "postcode": "",
                            "timeInYears": null,
                            "timeInMonths": null,
                            "firstIncome": null,
                            "secondIncome": null,
                            "otherIncomeDescription": null,
                            "partTimeHoursWorked": null
                        },
                        "previousEmploymentDetails": [
                            {
                                "occupationType": null,
                                "employmentStatus": null,
                                "employerName": "",
                                "address1": "",
                                "address2": "",
                                "address3": "",
                                "address4": "",
                                "postcode": "",
                                "timeInYears": null,
                                "timeInMonths": null,
                                "firstIncome": null,
                                "secondIncome": null,
                                "otherIncomeDescription": null,
                                "partTimeHoursWorked": null
                            }
                        ]
                    }
                ]
            }
        }

    }
}

const getCaseAssessorPayload = (applicant_id) => {
    return {
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
                  },
                  "jobCode": applicant_id
    }
}

const updateCaseAssessorPayload = (att, value, responseData) => {
    /**
     * {
                "jobCode": value,
                "multiRecordList": [{
                  "questions": [
                    {
                      "ItemType": "ItemField",
                      "Code": "10269",
                      "Questionnaire": null,
                      "Question": "Total Advance",
                      "Qno": 26,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor26,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                    {
                      "ItemType": "ItemField",
                      "Code": "10270",
                      "Questionnaire": null,
                      "Question": "Legal Fees",
                      "Qno": 27,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor27,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10271",
                      "Questionnaire": null,
                      "Question": "Conditions Precedent",
                      "Qno": 28,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor28,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "2",
                      "Questionnaire": null,
                      "Question": "Conditions Subsequent",
                      "Qno": 29,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor29,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10272",
                      "Questionnaire": null,
                      "Question": "Repayment",
                      "Qno": 30,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor30,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10273",
                      "Questionnaire": null,
                      "Question": "Security Cover Score",
                      "Qno": 31,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor31,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "2",
                      "Questionnaire": null,
                      "Question": "Asset Quality Score",
                      "Qno": 32,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor32,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10274",
                      "Questionnaire": null,
                      "Question": "Interest Cover Score",
                      "Qno": 33,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor33,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10275",
                      "Questionnaire": null,
                      "Question": "Exit Route Score",
                      "Qno": 34,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor34,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10276",
                      "Questionnaire": null,
                      "Question": "People Score",
                      "Qno": 35,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor35,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10277",
                      "Questionnaire": null,
                      "Question": "Operating Conditions",
                      "Qno": 36,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor36,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10278",
                      "Questionnaire": null,
                      "Question": "Ownership/Management/History",
                      "Qno": 37,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor37,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10279",
                      "Questionnaire": null,
                      "Question": "Proposal",
                      "Qno": 38,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor38,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "2",
                      "Questionnaire": null,
                      "Question": "Security",
                      "Qno": 39,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor39,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10280",
                      "Questionnaire": null,
                      "Question": "Financial Commentry",
                      "Qno": 40,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor40,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10281",
                      "Questionnaire": null,
                      "Question": "Summary Recommendation Pros",
                      "Qno": 41,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor41,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10282",
                      "Questionnaire": null,
                      "Question": "Summary Recommendation Cons",
                      "Qno": 42,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor42,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    },
                          {
                      "ItemType": "ItemField",
                      "Code": "10283",
                      "Questionnaire": null,
                      "Question": "Underwriters Comments",
                      "Qno": 43,
                      "QuestionType": "Text",
                      "HeaderText": null,
                      "Answer": att.caseAssessor43,
                      "AnswerList": null,
                      "IsMultiRecord": false,
                      "MultiRecordIndex": 0,
                      "AssessorID": 1
                    }
                  ]
                }],
                "UserInfo": {
                  "Username": "{{ _.username }}",
                  "Password": "{{ _.password }}"
                }
              }
     */
    let ret = {
        "jobCode": value,
        "multiRecordList": [{"questions": responseData.d.Records}],
        "UserInfo": {
            "Username": localStorage.getItem('bo_email'),
            "Password": localStorage.getItem('bo_password')
        }
    }
    
    // modify payload with answers 
    let matches = 0;
    for(let i=0; i<ret.multiRecordList[0].questions.length; i++) {
        let qno = ret.multiRecordList[0].questions[i].Qno;
        // let code = ret.multiRecordList[i].Code;
        if(typeof att['caseAssessor' + qno] !== 'undefined') { // caseAssessor36
            matches ++;
            ret.multiRecordList[0].questions[i].Answer = att['caseAssessor' + qno];
        }
    }

    return ret;
}

export default {
    executeCreateCaseJSONLend
};
