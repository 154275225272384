import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { config } from '../Configurator'

class Documents extends Component {

    constructor(props) {
        super(props)
        this.state = {
            initialized: false,
            context: 'cases',
            applicant_id: null,
            customer_reference: null,
            case_status: null,
            b2b: null,
            forms: config().forms
        }
    }

    componentDidMount() {
        let context = this.props.context ?? 'cases';

        
        // get case details
        axios.post(config().base_url + 'GetCase', {
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            },
            "CaseReference": this.props.applicant_id
        })
        .then(response => {

            console.log(response.data.d.CaseApplicant);
            
            // console.log('applicant_id = ' + response.data.d.CaseApplicant.ApplicantID);
            // console.log('customer_ref = ' + response.data.d.CaseApplicant.CustomerReference);

            this.setState({
                context: context, 
                initialized: true,
                applicant_id: response.data.d.CaseApplicant.ApplicantID,
                // b2b: (response.data.d.CaseApplicant.MainApplicant.WorkTelephone==null) ? false : true , 
                b2b: (response.data.d.CaseApplicant.B2B==true) ? true : false ,
                case_status: response.data.d.CaseApplicant.CaseStatus ,
                customer_reference: response.data.d.CaseApplicant.CustomerReference})
        })
        .catch(error => {
            console.log(error);
        });

    }

    handleUpload()
    {   
    }

    getPdf(entry)
    {
        // entry == { "name": "DOCX COMMON WITH LETTERGROUP",  "LetterGroupCode": "A", "type": "docx", "resource": "test" }
        
        // get documents
        axios.post(config().base_url + 'CreateDocumentToPdf', {
            "pRequest": {
                "Mode":"Case" ,
                "Reference": this.state.applicant_id,
                "LetterCode": entry.resource ?? "",
                "LetterGroupCode": entry.LetterGroupCode ?? "",
                "ReturnDocType": entry.ReturnDocType ?? ""
            },
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            }
        })
        .then(response => {
            let url = (response.data.d.ReturnUrl);
            var link = document.createElement('a');
            link.href = url;
            link.target = "_blank"
            link.download = 'download_' + entry.resource + '.pdf';
            link.dispatchEvent(new MouseEvent('click'));

            // this.setState({entry: response.data.d.CaseApplicant});
        })
        .catch(error => {
            console.log(error);
        });
    }

    render() {
        const { context, forms, applicant_id, customer_reference, initialized } = this.state;
        return (initialized) ? (
            <div>

                <div className="card">
                    <div className="card-body">

                        {
                            forms.map(entry => {

                                let get_query = '?';
                                let state_b2b = this.state.b2b;
                                let state_case_status = this.state.case_status; 
                                let entry_b2b = entry.b2b ?? null;
                                let entry_case_status = entry.case_status ?? null;

                                console.log('-------');

                                console.log('state_b2b=' + state_b2b);
                                console.log('state_case_status=' + state_case_status);

                                // console.log(state_case_status + ", context=" + context);

                                // show entry if b2b field is not set (default) 
                                // let logic_b2b = ((entry_b2b===false && state_b2b===false) || (entry_b2b===true && state_b2b===true)); 
                                let logic_b2b = (entry_b2b===true && state_b2b===true); 
                                let logic_case_status = (state_case_status!=null && ((state_case_status=='Live' && entry_case_status=='live') || (state_case_status=='Prospect' && entry_case_status=='prospect'))); 

                                console.log('logic conditions to be in the list = ' + (!entry_b2b && !entry_case_status) + ', ' + logic_case_status + ', ' + logic_b2b );

                                if((!entry_b2b && !entry_case_status) || logic_case_status || logic_b2b) {

                                    console.log('logic passes ____ entry type=' + entry.type + ' context=' + context);

                                    if(applicant_id) {
                                        get_query += "applicant_id=" + applicant_id + "&";
                                    }
                                    if(customer_reference) {
                                        get_query += "customer_reference=" + customer_reference + "&";
                                    }

                                    // LINKS
                                    if (entry.type === 'link' && applicant_id && !customer_reference) {
                                        return <Link className="btn btn-outline-dark btn-block btn-sm" to={entry.resource + '?applicant_id=' + applicant_id}>{entry.name}</Link>
                                    } 
                                    if (entry.type === 'link' && applicant_id && customer_reference) {
                                        return <Link className="btn btn-outline-dark btn-block btn-sm" to={entry.resource + '?customer_reference=' + customer_reference + '&applicant_id=' + applicant_id}>{entry.name}</Link>
                                    } 
                                    if (entry.type === 'link_prospect' && context=='prospects') {
                                        return <Link className="btn btn-outline-dark btn-block btn-sm" to={entry.resource + get_query}>{entry.name}</Link>
                                    } 
                                    if (entry.type==='link_case' && context==='cases') {
                                        return <Link className="btn btn-outline-dark btn-block btn-sm" to={entry.resource  + get_query}>{entry.name}</Link>
                                    } 


                                    // DOCX
                                    if (entry.type === 'docx') {
                                        return <Link target="_blank" className="btn btn-outline-dark btn-docx btn-block btn-sm" to={entry.resource}>{entry.name}</Link>
                                    } 
                                    if (entry.type === 'docx_prospect' && context=='prospects') {
                                        return <Link target="_blank" className="btn btn-outline-dark btn-docx btn-block btn-sm" to={entry.resource}>{entry.name}</Link>
                                    } 
                                    if (entry.type==='docx_case' && context==='cases') {
                                        return <Link target="_blank" className="btn btn-outline-dark btn-docx btn-block btn-sm" to={entry.resource}>{entry.name}</Link>
                                    } 

                                    // PDF
                                    if (entry.type === 'pdf') {
                                        return <Link className="btn btn-outline-dark btn-block btn-pdf btn-sm" onClick={() => this.getPdf(entry)}>{entry.name}</Link>
                                    }
                                    if (entry.type === 'pdf_prospect' && context=='prospects') {
                                        return <Link className="btn btn-outline-dark btn-block btn-pdf btn-sm" onClick={() => this.getPdf(entry)}>{entry.name}</Link>
                                    } 
                                    if (entry.type==='pdf_case' && context==='cases') {
                                        return <Link className="btn btn-outline-dark btn-block btn-pdf btn-sm" onClick={() => this.getPdf(entry)}>{entry.name}</Link>
                                    } 
                                }


                            })
                        }

                        {/* <br /><br /> */}

                        {/* <div className="btn btn-outline-dark btn-block btn-sm">Make Payment</div> */}
                        {/* <div className="btn btn-outline-dark btn-block btn-sm">Upload Docs</div>

                        <div className="upload-box">
                            <div className="input-group mt-3">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="myInput1" aria-describedby="myInput1" />
                                    <label className="custom-file-label" htmlFor="myInput1">Choose file</label>
                                </div>
                            </div>
                            <div className="meter">
                                <span><span className="meter-progress"></span></span>
                            </div>
                        </div>


                        <div className="upload-box">
                            <div className="input-group mt-3">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="myInput2" aria-describedby="myInput2" />
                                    <label className="custom-file-label" htmlFor="myInput2">Choose file</label>
                                </div>
                            </div>
                            <div className="meter">
                                <span><span className="meter-progress"></span></span>
                            </div>
                        </div>

                        <div className="upload-box">
                            <div className="input-group mt-3">
                                <div className="custom-file">
                                    <input type="file" onChange={this.handleUpload.bind(this)} className="custom-file-input" id="myInput3" aria-describedby="myInput3" />
                                    <label className="custom-file-label" htmlFor="myInput3">Choose file</label>
                                </div>
                            </div>
                            <div className="meter">
                                <span><span className="meter-progress"></span></span>
                            </div>
                        </div> */}

                        {/* <hr /> */}

                        {/* <br /> */}

                    </div>
                </div>

            </div>
        ) : "Loading..."
    }

}

export default Documents;