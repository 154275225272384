import React, { Component } from 'react';
import axios from 'axios';
import Loading from '../components/Loading';
import { Link } from 'react-router-dom';
import { config } from '../Configurator'

class DocumentsSharepoint extends Component {

    constructor(props) {
        super(props)
        this.state = {
            context: 'cases',
            loading: true, 
            xml: [],
            nav_history: [],
            applicant_id: null,
            customer_reference: null,
            forms: config().forms,
            folder: 'index',
            empty_folder: false
        }
    }

    componentDidMount() {

        let f = this.props.folder;

        let context = this.props.context ?? 'cases';
        this.setState({ 
            'context': context, 
            'folder': (f ? f : 'index'),
            'applicant_id': this.props.applicant_id, 
            'customer_reference': this.props.customer_reference}, function(){
                this.initializeData();
            });
    }

    initializeData()
    {
        this.realResponse('index');
    }

    realResponse(target='index')
    {
        let that = this;

        let nav_history = this.state.nav_history;
        nav_history.push(target);
        this.setState({'nav_history': nav_history});

        that.setState({'loading': true}, () => {

            if(target=='index') {
                target = "/sites/BO-TestSite/Shared%20Documents";
            } 
    
            // /_api/web/GetFolderByServerRelativeUrl('/sites/BO-TestSite/Shared%20Documents')?$expand=Folders,Files
            axios.get("https://crm.evelocity.info/sharepoint-api/?param=" + "_api/web/GetFolderByServerRelativeUrl('" + target + "')?$expand=Folders,Files").then(function (response) {
                
            
                console.log('here==== ');
                console.log(response.data.d);
                that.setState({'xml': response.data.d, 'folder': target, 'loading': false});
    
            });
        });
    }

    handleClick(targ) {
        // click on folder
        this.realResponse(targ);
    }

    handleGoBack() {

        this.handleClick('index');

        /*
        console.log('go back');
        let nav_history = this.state.nav_history;
        if(nav_history.length>=1) {
            nav_history.pop();
        }
        console.log(nav_history);
        console.log(nav_history[0]);

        if(nav_history.length==0) {
            console.log('to index');
            this.handleClick('index');
        } else {
            console.log(nav_history);
            console.log('to somewhere' . nav_history[nav_history.length-1]);
            this.handleClick(nav_history[(nav_history.length)-1]);
        }

        this.setState({'nav_history': nav_history});
        */
    }

    handleFileClick(file) {
        let url = ("https://rewardcf.sharepoint.com.mcas.ms" + file);
        window.open(url, '_blank').focus();
    }

    convertXmlToJson(xml)
    {

        try {
            var obj = {};
            if (xml.children.length > 0) {
            for (var i = 0; i < xml.children.length; i++) {
                var item = xml.children.item(i);
                var nodeName = item.nodeName;
        
                if (typeof (obj[nodeName]) == "undefined") {
                obj[nodeName] = this.convertXmlToJson(item);
                } else {
                if (typeof (obj[nodeName].push) == "undefined") {
                    var old = obj[nodeName];
        
                    obj[nodeName] = [];
                    obj[nodeName].push(old);
                }
                obj[nodeName].push(this.convertXmlToJson(item));
                }
            }
            } else {
            obj = xml.textContent;
            }
            return obj;
        } catch (e) {
            console.log(e.message);
        }          
    }

    render() {
        const { xml, loading, folder } = this.state;
        return (
            <div>

                <div className="card">
                    <div className="card-body">


                    {
                            loading ? <Loading></Loading> :
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>File/Folder Name</th>
                                    <th>Modified Date</th>
                                </tr>
                                </thead>
                                <tbody>

                                {(folder!='/sites/BO-TestSite/Shared%20Documents') ? <tr><td><a href="javascript:void(0)" onClick={() => this.handleGoBack()} > &larr; Back </a></td></tr> : ''}

                                {
                                    Array.isArray(xml.Folders.results) && xml.Folders.results.length ?
                                    xml.Folders.results.map(entry => {
                                        return (true) ? <tr key={entry.id}>
                                            <td><a className="text-dark" href="javascript:void(0)" onClick={() => this.handleClick(entry.ServerRelativeUrl)} ><i className="fa fa-folder"></i> { entry.Name } </a></td>
                                            <td>{ entry.TimeLastModified.replace('T', ' ').replace('Z', ' ') }</td>
                                        </tr> : ''
                                    }) : null
                                }
                                {
                                    Array.isArray(xml.Files.results) && xml.Files.results.length ?
                                    xml.Files.results.map(entry => {
                                        return (true) ? <tr key={entry.id}>
                                            <td><a href="javascript:void(0)" onClick={() => this.handleFileClick(entry.ServerRelativeUrl)} >{ entry.Name }</a></td>
                                            <td>{ entry.TimeLastModified.replace('T', ' ').replace('Z', ' ') }</td>
                                        </tr> : ''
                                    }) : null
                                }
                                

                                </tbody>
                        </table>
                    }

                    </div>
                </div>

            </div>
        )
    }
}

export default DocumentsSharepoint;