import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class CssCustomizer extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {

        
        let domain = window.location.hostname;

        console.log('domain=' + domain);
        // crm.evelocity.info

        if (domain) {
            let css = './css/domain/' + domain + '/default.css'; 
            this.addStyle(css);
        }

    }

    addStyle = url => {
        const style = document.createElement("link");
        style.href = url;
        style.rel = "stylesheet";
        style.async = true;
        console.log(style);
        document.head.appendChild(style);
    };

    render() {
        return null
    }

}

export default withRouter(CssCustomizer);