import React from 'react';
import Navbar from '../components/Navbar';

export default function Thanks(props) {
    return (
        <div>
            <Navbar />

            <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col-md-8">
                        <h1>Thank you</h1>

                        <p>Thank you for your submission. <br /><span className={` ${ (props.match.params.reference!='undefined') ? 'shown' : 'd-none'}`} >Your reference ID: <strong>{ props.match.params.reference }</strong></span></p>
                        <br /><br /><br />
                    </div>
                    <div className="col-md-4">
                    </div>
                </div>
            </div>


        </div>
    );
}