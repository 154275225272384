import React, { Component } from 'react';
import axios from 'axios';
import { Route, Link } from 'react-router-dom';
import Loading from './Loading';
import {convertDate} from '../Utils';
import { config } from '../Configurator'

class DataFolderArea extends Component {

    constructor(props) {
        super(props)
        this.state = {
            caseId: null,
            entries: [],
            loading: true
        }
    }

    componentDidMount() {

        this.setState({'caseId': this.props.caseId});

        //let endpoint = 'https://brokercrm.co.uk/Webservices/BrightOfficeAPI.asmx/GetDataFolderByParamsV1';
        //let username = 'srdjan.stojkovic';
        //let password = 'Fjehf*!jnx24gh';
        //let caseId = 'CA051405';

        let endpoint = config().base_url + 'GetDataFolderByParamsV1'
        let username = localStorage.getItem('bo_email')
        let password = localStorage.getItem('bo_password')
        let caseId = this.props.caseId;

        axios.post(endpoint, {
            "UserInfo": {
                "Username": username,
                "Password": password
            },
            "pRequest": {
                "FieldName": "ApplicantID",
                "Code": caseId // the case code
            }
        })
        .then(response => {
            // console.log(response.data.d.Records);
            this.setState({loading: false, entries: response.data.d.Records });
        })
        .catch(error => {
            console.log(error);
            this.setState({loading: false});
        });
    }

    render() {
        const { entries, loading } = this.state;
        return (
            <div className="card">
                <div className="card-body">
                    <div className="row">
                    <div className="col-12 mt-3">
                        {
                            loading ? <Loading></Loading> : null
                        }
                        {
                            entries.length ?
                            entries.map(entry => {
                                return <div key={ entry.ApplicantID } className="row mb-2">
                                    <div className="col-md-4">{ entry.Description }</div>
                                    <div className="col-md-2">{ convertDate(entry.DateReceived) }</div>
                                    <div className="col-md-4">{ entry.Notes }</div>
                                    <div className="col-md-2">

                                        {entry.CaseItemsOnFiles.map(function(d, idx){
                                            return (<a className="" href={d.Path}>View</a>)
                                        })}


                                    </div>
                                </div> 
                            }) : 
                            <p>No uploaded files</p>
                        }

                    </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default DataFolderArea;