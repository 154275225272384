import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { config } from '../Configurator'
import {convertDate, currency} from '../Utils';
import Documents from '../components/Documents'

class ProspectView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            entry: {},
            applicant_id: this.props.id,
            name: null,
        }
    }

    componentDidMount() {

        // get case details
        axios.post(config().base_url + 'GetCustomer', {
            "UserInfo": {
                "Username": localStorage.getItem('bo_email'),
                "Password": localStorage.getItem('bo_password')
            },
            "CustomerReference": this.props.id
        })
        .then(response => {
            // console.log(response.data.d.Customer);
            this.setState({
                entry: response.data.d.Customer
            })
        }).catch(error => {
            console.log(error);
        });


    }
    render() {
        const { entry, applicant_id } = this.state;
        return (
            <div>
                <div className="row mt-3">
                    <div className="col-md-8">
                        <div className="row mb-2">
                            <div className="col-9">
                                <h2>Prospect Summary</h2>
                            </div>
                            <div className="col-3 text-right">
                                <Link className="float-right btn btn-light btn-sm" to='/customers'>&larr; Back</Link>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                            <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-3">Reference:<br /><strong>{ entry.CustomerReference }</strong></div>
                                        <div className="mb-3">Company Name:<br /><strong>{ entry.CompanyName }</strong></div>
                                        <div className="mb-3">Contact Name:<br /><strong>{ entry.Firstname } { entry.Surname }</strong></div>
                                        <div className="mb-3">House Number/Name:<br /><strong>{ entry.CustomerAddress ? entry.CustomerAddress.HouseNumber : "" }{ entry.CustomerAddress ? entry.CustomerAddress.HouseName : "" }</strong></div>
                                        <div className="mb-3">Address 1:<br /><strong>{ entry.CustomerAddress ? entry.CustomerAddress.Address1 : "" }</strong></div>
                                        <div className="mb-3">Address 2:<br /><strong>{ entry.CustomerAddress ? entry.CustomerAddress.Address2 : "" }</strong></div>
                                        <div className="mb-3">Address 3:<br /><strong>{ entry.CustomerAddress ? entry.CustomerAddress.Address3 : "" }</strong></div>
                                        <div className="mb-3">Address 4:<br /><strong>{ entry.CustomerAddress ? entry.CustomerAddress.Address4 : "" }</strong></div>
                                        <div className="mb-3">PostCode:<br /><strong>{ entry.CustomerAddress ? entry.CustomerAddress.PostCode : "" }</strong></div>
                                    </div>
                                    
                                    <div className="col-md-4">
                                        <div className="mb-3">Telephone:<br /><strong>{ entry.Telephone }</strong></div>
                                        <div className="mb-3">Mobile:<br /><strong>{ entry.Mobile }</strong></div> 
                                        <div className="mb-3">Email:<br /><strong>{ entry.Email }</strong></div>
                                        <div className="mb-3">Website:<br /><strong>{ entry.Website }</strong></div>
                                        <div className="mb-3">Date Created:<br /><strong>{ convertDate(entry.DateCreated) }</strong></div>
                                        {/* <div className="mb-3">Source:<br /><strong>{ entry.Source }</strong></div> */}
                                        <div className="mb-3">Owner Name:<br /><strong>{ entry.OwnerName }</strong></div>
                                    </div>
                                    <div className="col-md-1"> </div>
                                    <div className="col-mb-3">Status:<br /><strong>{ entry.CustomerStatus }</strong></div>
                                </div>
                            </div>
                            <div className="card-footer">
                            <br />
                            <div className="row">
                                    <div className="col-mb-1"><br /><strong></strong></div>
                                    <div className="col-mb-6">Notes:<br /><strong>{ entry.Notes }</strong></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        
                        <h2 className="mb-3">Documents</h2>

                        <Documents context='prospects' applicant_id={applicant_id}></Documents>

                    </div>
                </div>
            </div>
        )
    }

}

export default ProspectView;